import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchRegistryItemsList, registryItemEditStart } from '../../actions/registryItems';
import { currencies } from '../../reducers/referenceData';
import { fetchingItemList, fetchRegistryItemListError, registryItems } from '../../reducers/registryItems';
import { currency } from '../../reducers/registryStore';
import Error from '../Error';
import RegistryItem from './RegistryItem';

const RegistryItems = ({
  match,
  currencies,
  currency,
  onFetchRegistryItems,
  registryPath,
  registryItems,
  isFetchingList,
  fetchRegistryItemListError,
}) => {
  const selectedCurrency = currencies.find(c => c.currencyCode === currency);

  if (fetchRegistryItemListError) {
    console.log('Error: ITEMS FETCH ERROR');
    return <Error error={'Error: ITEMS FETCH ERROR'} />;
  } else if (!registryItems.registryItems) {
    if (!isFetchingList) {
      console.log('!isFetchingList');
      onFetchRegistryItems(registryPath);
    }
    return <div>ITEMS NOT FETCHED</div>;
  } else if (registryItems.registryItems.length === 0) {
    console.log('no items');
    return <div>NO ITEMS</div>;
  } else {
    return (
      <div>
        <div className="container-fluid" style={{ maxWidth: '900px' }}>
          <div className="row">
            {registryItems.registryItems.map((item, index) => (
              <RegistryItem item={item} index={index} selectedCurrency={selectedCurrency} />
            ))}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    currency: currency(state, ownProps.registryPath),
    currencies: currencies(state),
    registryItems: registryItems(state),
    isFetchingList: fetchingItemList(state),
    fetchRegistryItemListError: fetchRegistryItemListError(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchRegistryItems: registryPath => {
    dispatch(fetchRegistryItemsList(registryPath));
  },
  onPurchaseItem: registryItemID => {
    ownProps.history.push(`/registry/${ownProps.match.params.registryID}/item/${registryItemID}`);
  },
  onEditItemDetails: registryItem => {
    dispatch(registryItemEditStart(registryItem));
    ownProps.history.push(`/registry/${ownProps.match.params.registryID}/item/${registryItem.registryItemID}/edit`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistryItems)
);
