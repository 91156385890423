import { call, put, takeEvery } from 'redux-saga/effects';
import * as registrySearchActions from '../actions/registrySearch';

function* watchFetchList() {
  yield takeEvery(registrySearchActions.REGISTRY_SEARCH_REQUEST, function* fetchSearchSaga({ searchParam }) {
    if (searchParam.length > 0) {
      try {
        const response = yield call(
          fetch,
          `${process.env.REACT_APP_API_URL}/api/registry?q=${encodeURIComponent(searchParam)}`
        );
        const registryResults = yield response.json();
        yield put(registrySearchActions.registrySearchDone(registryResults));
      } catch (err) {
        yield put(registrySearchActions.registrySearchError(err));
      }
    } else {
      yield put(registrySearchActions.registrySearchDone([]));
    }
  });
}

export default [call(watchFetchList)];
