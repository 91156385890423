import {
  FETCH_REFERENCE_DATA_REQUEST,
  FETCH_REFERENCE_DATA_SUCCESS,
  FETCH_REFERENCE_DATA_FAILURE,
} from '../actions/referenceData';

// selectors
const name = 'referenceData';

export const countries = ({ [name]: { countries } }) => countries;
export const currencies = ({ [name]: { currencies } }) => currencies;
export const titles = ({ [name]: { titles } }) => titles;
export const registryTypes = ({ [name]: { registryTypes } }) => registryTypes;
export const registryItemCategories = ({ [name]: { registryItemCategories } }) => registryItemCategories;
export const purchaseType = ({ [name]: { purchaseType } }) => purchaseType;

// reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_REQUEST:
      return {
        ...state,
        fetchingReferenceData: true,
        fetchingReferenceDataError: undefined,
      };
    case FETCH_REFERENCE_DATA_SUCCESS:
      return {
        ...state,
        fetchingReferenceData: undefined,
        fetchingReferenceDataError: undefined,
        referenceData: action.list,
      };
    case FETCH_REFERENCE_DATA_FAILURE:
      return {
        ...state,
        fetchingReferenceData: undefined,
        fetchingReferenceDataError: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
