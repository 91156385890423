import {
  REGISTRY_SEARCH_REQUEST,
  REGISTRY_SEARCH_SUCCESS,
  REGISTRY_SEARCH_FAILURE,
  REGISTRY_SEARCH_EDITING,
} from '../actions/registrySearch';

const name = 'registryStore';

// selectors
export const registrySearchText = ({ [name]: { registrySearch } }) => registrySearch.searchParam;
export const registrySearchResults = ({ [name]: { registrySearch } }) => registrySearch.searchResults;
export const registrySearchBusy = ({ [name]: { registrySearch } }) => registrySearch.searchBusy;
export const registrySearchError = ({ [name]: { registrySearch } }) => registrySearch.searchError;

// reducer
const reducer = (state = {}, action) => {
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                     SEARCH:                                                    //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  switch (action.type) {
    case REGISTRY_SEARCH_REQUEST:
      return {
        ...state,
        searchParam: action.searchParam,
        searchBusy: true,
        searchError: undefined,
      };
    case REGISTRY_SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.list,
        searchBusy: undefined,
        searchError: undefined,
      };
    case REGISTRY_SEARCH_EDITING:
      return {
        ...state,
        searchParam: action.searchParam,
        searchBusy: undefined,
        searchError: undefined,
      };
    case REGISTRY_SEARCH_FAILURE:
      return {
        ...state,
        registrySearch: {},
        searchBusy: undefined,
        searchError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                    DEFAULT:                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
};

export default reducer;
