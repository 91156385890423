import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signUpEditing, signUpRequest } from '../../actions/authentication';
import { newUser, signUpDone, signUpBusy, signUpError } from '../../reducers/authentication';

const validation = () => {
  return true;
};

const Signup = ({ newUser, onEditNewUser, onSubmitNewUser, signUpBusy, signUpDone, signUpError }) => {
  // https://tylermcginnis.com/react-router-query-strings/
  // https://serverless-stack.com/chapters/create-the-signUp-form.html
  if (signUpDone)
    return (
      <div>
        <h1>Signup Success!</h1>
        <p>Signup has completed, please check your email for a confirmation email</p>
      </div>
    );
  else
    return (
      <div>
        <br />
        <h1>Signup:</h1>
        <br />
        <div className="container" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
          <h5>Email Signup:</h5>

          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Your name..."
              value={newUser.firstName || ''}
              onChange={({ target }) => onEditNewUser('firstName', target.value)}
            />
          </div>

          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Your surname..."
              value={newUser.lastName || ''}
              onChange={({ target }) => onEditNewUser('lastName', target.value)}
            />
          </div>

          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              className="form-control"
              placeholder="Email address..."
              value={newUser.email || ''}
              onChange={({ target }) => onEditNewUser('email', target.value)}
            />
          </div>

          <div className="form-group">
            <label>Confirm Email:</label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              placeholder="Confirm Email address..."
              value={newUser.emailConfirm || ''}
              onChange={({ target }) => onEditNewUser('emailConfirm', target.value)}
            />
          </div>

          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter a password"
              value={newUser.password || ''}
              onChange={({ target }) => onEditNewUser('password', target.value)}
            />

            <small id="registryTitleHelp" className="form-text text-muted">
              6 characters minimum
            </small>
          </div>

          <div className="form-group">
            <label>Confirm Password:</label>
            <input
              disabled={true}
              type="password"
              className="form-control"
              placeholder="Retype your password"
              value={newUser.passwordConfirm || ''}
              onChange={({ target }) => onEditNewUser('passwordConfirm', target.value)}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary" onClick={() => validation() && onSubmitNewUser(newUser)}>
              Sign Up
            </button>
            <br />
            <br />
          </div>
        </div>
        <p>Busy: {signUpBusy ? 'signUpBusy' : '-'}</p>
        <p>Error: {signUpError ? signUpError.message : '-'}</p>
      </div>
    );
};

Signup.defaultProps = {
  newUser: {},
};

const mapStateToProps = (state, ownProps) => ({
  newUser: newUser(state),
  signUpBusy: signUpBusy(state),
  signUpDone: signUpDone(state),
  signUpError: signUpError(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditNewUser: (field, value) => {
    dispatch(signUpEditing(field, value));
  },
  onSubmitNewUser: newUser => {
    dispatch(signUpRequest(newUser));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Signup)
);
