import React from 'react';

const Error = ({ error }) => {
  return (
    <div>
      <br />
      <h1>There was an Error... :'(</h1>
      <p>{error || 'No Error Supplied'}</p>
    </div>
  );
};

export default Error;
