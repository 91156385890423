import React from 'react';
import { Formik, Form, Field, ErrorMessage, useFormikContext, getIn } from 'formik';

import * as Yup from 'yup';

import { ReCaptcha } from 'react-recaptcha-v3';

import axios from 'axios';

// TODO: implement Mask on phone number
// https://codesandbox.io/s/4rkyylp24w

// Recaptcha
// https://www.npmjs.com/package/react-recaptcha-v3

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  tel: Yup.string()
    .min(10, 'Too Short!')
    .max(15, 'Too Long!')
    .required('Required'),
  subject: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  message: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

const Contact = () => {
  return (
    <div className="container">
      <br />
      <h1>Contact us</h1>
      <h6>Note: there is a known bug where the recaptcha fails after 2 minutes</h6>
      <br />

      {/* https://medium.com/@sgobinda007/send-email-from-your-node-js-application-by-using-mailgun-api-services-bbedc7c45c19 */}

      <br />
      <div className="row">
        <div className="col-md-5">
          <h3 style={{ marginBottom: '25px', textAlign: 'center' }}>Contact Form</h3>
          {/* <p>
            This site is protected by reCAPTCHA and the Google <br />
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and <br />
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p> */}

          <Formik
            initialValues={{
              token: '',
              name: '',
              email: '',
              tel: '',
              subject: '',
              message: '',
            }}
            validationSchema={ContactSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                const request = axios.post(
                  `${process.env.REACT_APP_API_URL}/api/Contact/sendForm`,
                  JSON.stringify(values, null, 2),
                  { headers: { 'Content-Type': 'application/json' } }
                );

                request
                  .then(res => {
                    actions.setStatus({ success: 'Your message was sent, we will get back to you soon!' });
                  })
                  .catch(err => {
                    if (err && err.response && err.response.status) {
                      if (err.response.status === 422) {
                        if (err.response.data && err.response.data.message)
                          actions.setStatus({ error: err.response.data.message });
                      }
                    } else {
                      actions.setStatus({ error: JSON.stringify(err.response.data, null, 2) });
                      actions.setSubmitting(false);
                    }
                  });
              }, 400);
            }}
          >
            {({ status, isSubmitting, setFieldValue }) =>
              status && status.success ? (
                <div>{status.success}</div>
              ) : (
                <Form>
                  <div className="form-group">
                    <Field type="text" name="name" className="form-control" placeholder="Name" />
                    <ErrorMessage name="name" component="div" />
                  </div>
                  <div className="form-group">
                    <Field type="email" name="email" className="form-control" placeholder="Email" />
                    <ErrorMessage name="email" component="div" />
                  </div>
                  <div className="form-group">
                    <Field type="text" name="tel" className="form-control" placeholder="Tel Number" />
                    <ErrorMessage name="tel" component="div" />
                  </div>
                  <div className="form-group">
                    <Field type="text" name="subject" className="form-control" placeholder="Subject" />
                    <ErrorMessage name="subject" component="div" />
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      component="textarea"
                      name="message"
                      className="form-control"
                      placeholder="Message"
                      maxLength="140"
                      rows="7"
                    />
                    <ErrorMessage name="message" component="div" />
                  </div>
                  <div className="form-group">
                    <ReCaptcha
                      sitekey="6LdoUq8UAAAAAM3d5lLFlo3A_U9gAMBKxhkBNGKl"
                      action="send_contactForm"
                      verifyCallback={response => {
                        console.log('token', response);
                        setFieldValue('token', response);
                      }}
                    />
                  </div>
                  {status && status.error ? (
                    <div className="alert">
                      {status.error}
                      <br />
                      Please try emailing us....
                    </div>
                  ) : (
                    <button type="submit" disabled={isSubmitting} className="btn btn-primary pull-right">
                      Submit Form
                    </button>
                  )}
                  <div>{status ? status.success : ''}</div>
                </Form>
              )
            }
          </Formik>
          <br />
          <br />
        </div>
        <div className="col-md-5">
          {/* <h3 style={{ marginBottom: '25px', textAlign: 'center' }}>Contact us</h3> */}
          email: kylemac06@gmail.com
        </div>
      </div>
    </div>
  );
};

export default Contact;
