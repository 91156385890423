import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../reducers';
import rootSaga from '../sagas';

const configureStore = preloadedState => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, preloadedState, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(rootSaga);

  store.subscribe(() => {
    console.log('STORE: ', store.getState());
  });

  return store;
};

export default configureStore;
