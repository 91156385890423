import {
  NEW_OFFSITE_TRANSACTION_EDITING,
  NEW_OFFSITE_TRANSACTION_FAILURE,
  NEW_OFFSITE_TRANSACTION_REQUEST,
  NEW_OFFSITE_TRANSACTION_SUCCESS,
} from '../actions/offsiteTransaction';

const name = 'offsiteTransaction';

// selectors
export const newOffsiteTransactionQuantity = ({ [name]: { quantity } }) => quantity;
export const newOffsiteTransactionBusy = ({ [name]: { newOffsiteTransactionBusy } }) => newOffsiteTransactionBusy;
export const newOffsiteTransactionDone = ({ [name]: { newOffsiteTransactionDone } }) => newOffsiteTransactionDone;
export const newOffsiteTransactionError = ({ [name]: { newOffsiteTransactionError } }) => newOffsiteTransactionError;

// reducer
const reducer = (state = {}, action) => {
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                            NEW OFFSITE TRANSACTION:                                            //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  switch (action.type) {
    case NEW_OFFSITE_TRANSACTION_EDITING:
      return {
        ...state,
        quantity: action.quantity,
      };

    case NEW_OFFSITE_TRANSACTION_REQUEST:
      return {
        ...state,
        newOffsiteTransactionBusy: true,
        newOffsiteTransactionError: undefined,
        newOffsiteTransactionDone: undefined,
      };
    case NEW_OFFSITE_TRANSACTION_SUCCESS:
      return {
        ...state,
        quantity: undefined, // reset the quantity
        newOffsiteTransactionBusy: undefined,
        newOffsiteTransactionError: undefined,
        newOffsiteTransactionDone: true,
      };
    case NEW_OFFSITE_TRANSACTION_FAILURE:
      return {
        ...state,
        newOffsiteTransactionBusy: undefined,
        newOffsiteTransactionError: action.error,
        newOffsiteTransactionDone: undefined,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                    DEFAULT:                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
};

export default reducer;
