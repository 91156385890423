const registryItemCategories = [
  {
    key: 'B',
    value: 'Bathroom',
  },
  {
    key: 'C',
    value: 'Camping',
  },
  {
    key: 'D',
    value: 'Home and Decor',
  },
  {
    key: 'E',
    value: 'Electronics',
  },
  {
    key: 'F',
    value: 'Fitness',
  },
  {
    key: 'H',
    value: 'For Him',
  },
  {
    key: 'K',
    value: 'Kitchen',
  },
  {
    key: 'L',
    value: 'Bedroom', // Linen
  },
  {
    key: 'O',
    value: 'Other',
  },
  {
    key: 'R',
    value: 'Books',
  },
  {
    key: 'S',
    value: 'For Her',
  },
  {
    key: 'T',
    value: 'Baby',
  },
  {
    key: 'Z',
    value: 'Cash',
  },
];

export default registryItemCategories;
