import { all } from 'redux-saga/effects';
import registrySagas from './registrySaga';
import registrySearchSaga from './registrySearchSaga';
import registryItemSaga from './registryItemSaga';
import authenticationSaga from './authenticationSaga';
import offsiteTransactionSaga from './offsiteTransactionSaga';

function* rootSaga() {
  yield all([
    ...registrySagas,
    ...registrySearchSaga,
    ...authenticationSaga,
    ...registryItemSaga,
    ...offsiteTransactionSaga,
  ]);
}

export default rootSaga;
