export function extractHostname(url) {
  url = url || '';
  var hostname;
  hostname = url.indexOf('//') > -1 ? url.split('/')[2] : url.split('/')[0];
  hostname = hostname.split(':')[0];
  hostname = hostname.split('?')[0];
  return hostname;
}

export function extractRootDomain(url) {
  var domain = extractHostname(url),
    splitArr = domain.split('.'),
    arrLen = splitArr.length;
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
    if (splitArr[arrLen - 2].length === 2 && splitArr[arrLen - 1].length === 2) {
      domain = splitArr[arrLen - 3] + '.' + domain;
    }
  }
  return domain;
}
