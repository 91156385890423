import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ownedRegistriesFetch } from '../../actions/registry';
import { ownedRegistryList, ownedRegistriesFetchError } from '../../reducers/registryStore';
import Error from '../Error';

export const RegistryPropType = {
  registryTitle: PropTypes.string.isRequired,
  country: PropTypes.string,
};

export const RegistryListPropTypes = {
  registries: PropTypes.arrayOf(PropTypes.shape(RegistryPropType)),
};

const columns = [
  {
    Header: 'Title',
    accessor: 'registryTitle',
  },
  {
    Header: 'Type',
    accessor: 'registryTypeID',
  },
  {
    Header: 'Country',
    accessor: 'countryCode',
  },
  {
    Header: 'Created Date',
    accessor: 'createdDate',
    show: false, // Sorting Column
  },
];

const Owned = ({ ownedRegistryList, ownedRegistriesFetchError, onRegistriesFetch, history }) => {
  if (ownedRegistriesFetchError) {
    return <Error error={ownedRegistriesFetchError.message} />;
  } else {
    return (
      <div>
        <div className="registry-table">
          <h1>Owned Registries:</h1>
          <i>These are registries that you own</i>
          <div className="border">
            <button className="btn btn-secondary" onClick={onRegistriesFetch}>
              Fetch List
            </button>
          </div>

          <br />
          <ReactTable
            className="-striped -highlight"
            data={ownedRegistryList}
            columns={columns}
            //sorted={[{ id: "createdDate" }]}
            getTrProps={handleRowClick(history)}
          />
        </div>
      </div>
    );
  }
};

Owned.propTypes = RegistryListPropTypes;

const handleRowClick = ({ push }) => (state, rowInfo) => {
  return {
    onClick: () => {
      console.log('rowInfo', rowInfo);
      // TODO: first check if we have an ID...
      push(`registry/${rowInfo.original.registryPath}`);
    },
  };
};

const mapStateToProps = state => ({
  ownedRegistryList: ownedRegistryList(state),
  ownedRegistriesFetchError: ownedRegistriesFetchError(state),
});

const mapDispatchToProps = dispatch => ({
  onRegistriesFetch: () => {
    dispatch(ownedRegistriesFetch());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Owned)
);
