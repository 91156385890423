import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const SearchSelection = ({ onClick }) => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="card" onClick={() => onClick('owner')}>
              <div className="card-body">
                <h5 className="card-title">Owner</h5>
                <p className="card-text">Registries that you own.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card" onClick={() => onClick('admin')}>
              <div className="card-body">
                <h5 className="card-title">Admin</h5>
                <p className="card-text">Registries that you admin.</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card" onClick={() => onClick('member')}>
              <div className="card-body">
                <h5 className="card-title">All</h5>
                <p className="card-text">All for now...</p>
                {/* <p className="card-text">Private registries that you are a member of.</p> */}
              </div>
            </div>
          </div>
          <br />
          <br />
          <hr />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: path => {
    ownProps.history.push(`${ownProps.match.url}#${path}`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchSelection)
);
