import registryItems from './registryItems';
import registryList from './registryList';

const registryStore = {
  registryItems,
  registryList,
  registrySearch: {},
};

export default registryStore;
