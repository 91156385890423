import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { registriesFetch } from '../../actions/registry';
import { registriesFetchError, registryList } from '../../reducers/registryStore';
import { withRouter } from 'react-router-dom';
import { registryTypes } from '../../reducers/referenceData';
import Error from '../Error';

export const RegistryPropType = {
  registryTitle: PropTypes.string.isRequired,
  country: PropTypes.string,
};

export const RegistryListPropTypes = {
  registries: PropTypes.arrayOf(PropTypes.shape(RegistryPropType)),
};

const columns = [
  {
    Header: 'Title',
    accessor: 'registryTitle',
  },
  {
    Header: 'Type',
    accessor: 'registryTypeID',
  },
  {
    Header: 'Country',
    accessor: 'countryCode',
  },
  {
    Header: 'Created Date',
    accessor: 'createdDate',
    show: false, // Sorting Column
  },
];

const Member = ({ registryList, registriesFetchError, onregistriesFetch, history }) => {
  if (registriesFetchError) {
    return <Error error={registriesFetchError} />;
  } else {
    return (
      <div>
        <div className="registry-table">
          <h1>All Registries:</h1>
          {/* TODO: */}
          <i>At the moment this returns all the registries</i>
          <br />
          <i>At some point we will introduce private registries that you would find here as a member</i>

          <div className="border">
            <button className="btn btn-secondary" onClick={onregistriesFetch}>
              Fetch List
            </button>
          </div>

          <br />
          <ReactTable
            className="-striped -highlight"
            data={registryList}
            columns={columns}
            sorted={[{ id: 'createdDate' }]}
            getTrProps={handleRowClick(history)}
          />
        </div>
      </div>
    );
  }
};

Member.propTypes = RegistryListPropTypes;

const handleRowClick = ({ push }) => (state, rowInfo) => {
  return {
    onClick: () => {
      // TODO: first check if we have an ID...
      push(`registry/${rowInfo.original.registryPath}`);
    },
  };
};

const mapStateToProps = state => ({
  registryList: registryList(state),
  registriesFetchError: registriesFetchError(state),
  registryTypes: registryTypes(state),
});

const mapDispatchToProps = dispatch => ({
  onregistriesFetch: () => {
    dispatch(registriesFetch());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Member)
);
