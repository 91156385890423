import { SETTINGS_REGISTRY_TOGGLE_DETAILS } from '../actions/settings';

// selectors
const name = 'settings';

export const registryComponentSettings = ({ [name]: { registryComponentSettings } }) => registryComponentSettings;
export const toggle = ({ [name]: { registryComponentSettings } }) => registryComponentSettings;

// reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_REGISTRY_TOGGLE_DETAILS:
      return {
        ...state,
        registryComponentSettings: {
          ...state.registryComponentSettings,
          showRegistryDetails: !state.registryComponentSettings.showRegistryDetails,
        },
      };
    default:
      return state;
  }
};

export default reducer;
