import countries from './countries';
import currencies from './currencies';
import registryItemCategories from './registryItemCategories';
import registryTypes from './registryTypes';
import shops from './shops';
import titles from './titles';
import purchaseType from './purchaseType';

const referenceData = {
  countries,
  currencies,
  registryItemCategories,
  registryTypes,
  shops,
  titles,
  purchaseType,
};
export default referenceData;
