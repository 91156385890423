import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import defaultStore from './store/defaultStore';

const store = defaultStore;

Sentry.init({ dsn: 'https://1343eb836e004d0d98e738b1a2e7b3c1@sentry.io/1515445' });

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
