import React from 'react';
import { connect } from 'react-redux';
import { registryDelete, editRegistryFields, registrySave } from '../../actions/registry';
import { countries, currencies, registryTypes } from '../../reducers/referenceData';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  registry,
  fetchingRegistriesBusy,
  registriesFetchError,
  deleteingRegistryBusy,
  deleteingRegistryError,
} from '../../reducers/registryStore';

const RegistryAddEditItem = ({
  fetchingRegistriesBusy,
  registriesFetchError,
  deleteingRegistryBusy,
  deleteingRegistryError,

  countries,
  currencies,
  registryTypes,
  registry,

  history,

  onDeleteRegistry,
  onEditRegistryField,
  onSaveRegistry,
}) => {
  let countryOptions = [<option key=" " />].concat(
    countries.map(o => (
      <option key={o.countryCode} value={o.countryCode}>
        {o.countryName}
      </option>
    ))
  );
  let currencyOptions = [<option key=" " />].concat(
    currencies.map(o => (
      <option key={o.currencyCode} value={o.currencyCode}>
        {o.description + ' (' + o.symbol + ')'}
      </option>
    ))
  );
  let registryTypeOptions = [<option key=" " />].concat(
    registryTypes.map(rt => (
      <option key={rt.key} value={rt.key}>
        {rt.value}
      </option>
    ))
  );

  if (fetchingRegistriesBusy) {
    return <div>loading registry...</div>;
  } else if (registriesFetchError) {
    return <div>ERROR Loading registries...</div>;
  } else if (deleteingRegistryBusy) {
    return <div>deleting Registry...</div>;
  } else if (deleteingRegistryError) {
    return <div>ERROR Deleting Registry...</div>;
  } else {
    return (
      <div>
        <h1>Edit your registry</h1>
        <div className="w-75 mx-auto border rounded" style={{ maxWidth: '750px' }}>
          <div className=" m-3">
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Registry Title..."
                value={registry.registryTitle}
                onChange={({ target }) => {
                  onEditRegistryField(registry, 'registryTitle', target.value);
                }}
              />
              <small id="registryTitleHelp" className="form-text text-muted">
                Please enter a friendly name for your registry.
              </small>
            </div>

            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Registry Description..."
                value={registry.registryDescription}
                onChange={({ target }) => {
                  onEditRegistryField(registry, 'registryDescription', target.value);
                }}
              />
            </div>

            <div className="form-group">
              <label>Registry Type</label>
              <select
                value={registry.registryTypeID}
                className="form-control"
                onChange={({ target }) => {
                  onEditRegistryField(registry, 'registryTypeID', target.value);
                }}
              >
                {registryTypeOptions}
              </select>
            </div>

            <div className="form-group">
              <label>EventDate</label>
              <input
                type="Date"
                className="form-control"
                placeholder="Event Date ..."
                value={registry.eventDate}
                onChange={({ target }) => {
                  onEditRegistryField(registry, 'eventDate', target.value);
                }}
              />
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                checked={registry.expires}
                className="form-check-input"
                onChange={({ target }) => {
                  onEditRegistryField(registry, 'expires', target.checked);
                }}
              />
              <label className="form-check-label">Registry expires</label>
            </div>
            <div hidden={!registry.expires} className="form-group">
              <label>Date of Expiry</label>
              <input
                type="date"
                className="form-control"
                value={moment(registry.expireDate).format('YYYY-MM-DD')}
                onChange={({ target }) => {
                  onEditRegistryField(registry, 'expireDate', target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label>Registry Owners</label>
              <textarea disabled={true} className="form-control" value={undefined} onChange={undefined} />
              {/* https://jedwatson.github.io/react-select/  >> Muti-select (really cool) */}
            </div>
            <div className="form-group">
              <label>Country:</label>
              <select
                value={registry.countryCode}
                className="form-control"
                onChange={({ target }) => {
                  onEditRegistryField(registry, 'countryCode', target.value);
                }}
              >
                {countryOptions}
              </select>
            </div>
            <div className="form-group">
              <label>Currency:</label>
              <select
                value={registry.currency}
                className="form-control"
                onChange={({ target }) => {
                  onEditRegistryField(registry, 'currency', target.value);
                }}
              >
                {currencyOptions}
              </select>
            </div>
            <br />
            <div>
              <div className="d-flex float-left">
                <button className="btn btn-danger" onClick={() => onDeleteRegistry(registry.registryID)}>
                  Delete registry
                </button>
              </div>
              <div className="d-flex float-right ml-2">
                <button className="btn btn-primary" onClick={() => onSaveRegistry(registry)}>
                  Save
                </button>
              </div>
              <div className="d-flex float-right">
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    registry.registryID ? history.push(`/registry/${registry.registryID}`) : history.push('/');
                  }}
                >
                  Back
                </button>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
};

RegistryAddEditItem.defaultProps = {
  registry: {},
};

const mapStateToProps = (state, ownProps) => ({
  fetchingRegistriesBusy: fetchingRegistriesBusy(state),
  registriesFetchError: registriesFetchError(state),
  deleteRegistryBusy: deleteingRegistryBusy(state),
  deleteRegistryError: deleteingRegistryError(state),
  countries: countries(state),
  registryTypes: registryTypes(state),
  currencies: currencies(state),
  history: ownProps.history,
  registry: registry(state, ownProps.match.params.registryID),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditRegistryField: (registry, fieldName, newValue) => {
    dispatch(editRegistryFields(registry, fieldName, newValue));
  },

  onSaveRegistry: registry => {
    dispatch(registrySave(registry));
    ownProps.history.push(`/registry/${registry.registryPath}`);
  },

  onDeleteRegistry: registryPath => {
    dispatch(registryDelete(registryPath));
    ownProps.history.push(`/`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistryAddEditItem)
);
