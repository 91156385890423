import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const Admin = () => {
  return (
    <div>
      <h1>Admin Registries:</h1>
      <i>This functionality has not yet been built.</i>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Admin)
);
