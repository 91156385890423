import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { confirmUser } from '../../actions/authentication';
import { confirmationDone, confirmationBusy, confirmationError } from '../../reducers/authentication';
import Error from '../Error';

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
const UserConfirmation = ({
  match,
  location,
  goToLogin,
  onConfirmAccount,
  confirmationDone,
  confirmationBusy,
  confirmationError,
}) => {
  console.log(location);
  //https://tylermcginnis.com/react-router-query-strings/
  const values = queryString.parse(location.search);
  console.log(decodeURI(values.userID));
  console.log(decodeURI(values.token));

  if (confirmationError) {
    console.log('confirmationError.response', confirmationError.response);
    return <Error error={confirmationError.message} />;
  } else if (confirmationDone) {
    return (
      <div>
        <p>You're all done!</p>
        <p>Click below to log in</p>
        <button className="btn btn-primary" onClick={() => goToLogin()}>
          Go to Login
        </button>
      </div>
    );
  } else if (confirmationBusy) {
    return (
      <div>
        <h1>User Confirmation:</h1>
        <p>We are confirming you account... please wait</p>
      </div>
    );
  } else {
    onConfirmAccount(values.userID, values.token);
    return (
      <div>
        <h1>User Confirmation:</h1>
        <p>We are Starting the confimation</p>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => ({
  confirmationDone: confirmationDone(state),
  confirmationBusy: confirmationBusy(state),
  confirmationError: confirmationError(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onConfirmAccount: (userID, token) => {
    dispatch(confirmUser(userID, token));
  },
  goToLogin: () => {
    ownProps.history.push('/login');
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserConfirmation)
);
