import { call, put, takeEvery } from 'redux-saga/effects';
import * as registryItemActions from '../actions/registryItems';

function* watchFetchList() {
  yield takeEvery(registryItemActions.REGISTRY_ITEMS_FETCH_REQUEST, function* fetchListSaga(registry) {
    try {
      const registryID = registry.registryID;
      const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/api/registry/${registryID}/items`);
      const registryItemList = yield response.json();
      yield put(registryItemActions.fetchRegistryItemsListDone(registryID, registryItemList));
    } catch (err) {
      yield put(registryItemActions.fetchRegistryItemsListError(err));
    }
  });

  yield takeEvery(registryItemActions.NEW_REGISTRY_ITEM_INSERT_REQUEST, function* addRegistryItem({ newRegistryItem }) {
    try {
      const postedRegistryItem = yield fetch(
        `${process.env.REACT_APP_API_URL}/api/registry/${newRegistryItem.registry.registryID}/items`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newRegistryItem),
        }
      ).then(response => {
        if (response.status >= 400) {
          throw new Error(`${response.status} (${response.statusText})`);
        } else {
          return response.json();
        }
      });
      yield put(registryItemActions.addRegistryItemDone(postedRegistryItem));
    } catch (err) {
      yield put(() => registryItemActions.addRegistryItemError(err));
    }
  });

  yield takeEvery(registryItemActions.REGISTRY_ITEM_DELETE_REQUEST, function* deleteRegistryItemSaga({
    registryID,
    registryItemID,
  }) {
    try {
      const response = yield call(
        fetch,
        `${process.env.REACT_APP_API_URL}/api/registry/${registryID}/items/${registryItemID}`,
        {
          method: 'DELETE',
        }
      );
      const status = yield response;

      if (status.status >= 400) {
        throw new Error(`${response.status} (${response.statusText})`);
      } else {
        yield put(registryItemActions.registryItemDeleteDone(registryItemID));
      }
    } catch (err) {
      yield put(registryItemActions.registryItemDeleteError(err));
    }
  });

  yield takeEvery(registryItemActions.REGISTRY_ITEM_EDIT_SAVE_REQUEST, function* saveEditingItem({ editingItem }) {
    const { registryID, registryItemID, ...rest } = editingItem;
    try {
      const action = yield fetch(
        `${process.env.REACT_APP_API_URL}/api/registry/${registryID}/items/${registryItemID}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...rest }),
        }
      ).then(response => {
        if (response.status >= 400) {
          throw new Error(`${response.status} (${response.statusText})`);
        } else {
          return registryItemActions.registryItemEditSaveDone(editingItem);
        }
      });
      yield put(action);
    } catch (err) {
      yield put(registryItemActions.registryItemEditSaveError(err.message));
    }
  });
}
export default [call(watchFetchList)];
