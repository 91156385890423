import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const NotLoggedIn = ({ returnUrl, onGoToLogin }) => {
  console.log('NLIretUrl', returnUrl);
  return (
    <div>
      <br />
      <h1>You need to be logged in to access this page:</h1>
      <p>Click here to log in:</p>
      <button className="btn btn-primary" onClick={() => onGoToLogin(returnUrl)}>
        Login
      </button>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onGoToLogin: returnUrl => {
    console.log('NLIreturnUrl: ', returnUrl);
    returnUrl ? ownProps.history.push(`/login?returnUrl=${returnUrl}`) : ownProps.history.push(`/login`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NotLoggedIn)
);
