import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutRequest } from '../../actions/authentication';

const Logout = ({ onLogout }) => {
  return (
    <div>
      <br />
      <h1>Logout:</h1>
      <p>Are you sure you want to log out?</p>
      <button className="btn btn-primary" onClick={() => onLogout()}>
        LOGOUT
      </button>
    </div>
  );
};

Logout.defaultProps = {};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogout: () => {
    localStorage.removeItem('userToken');
    dispatch(logoutRequest());
    ownProps.history.push('/');
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Logout)
);
