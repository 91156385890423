import { combineReducers } from 'redux';
import registryStore from './registryStore';
import referenceData from './referenceData';
import authentication from './authentication';
import settings from './settings';
import offsiteTransaction from './offsiteTransaction';

const combined = combineReducers({
  registryStore,
  referenceData,
  settings,
  authentication,
  offsiteTransaction,
});

export default combined;
