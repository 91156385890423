// constants
export const REGISTRY_EDITING = 'REGISTRY_EDITING';

export const REGISTRY_DELETE_REQUEST = 'REGISTRY_DELETE_REQUEST';
export const REGISTRY_DELETE_SUCCESS = 'REGISTRY_DELETE_SUCCESS';
export const REGISTRY_DELETE_FAILURE = 'REGISTRY_DELETE_FAILURE';

export const REGISTRY_SAVE_REQUEST = 'REGISTRY_SAVE_REQUEST';
export const REGISTRY_SAVE_SUCCESS = 'REGISTRY_SAVE_SUCCESS';
export const REGISTRY_SAVE_FAILURE = 'REGISTRY_SAVE_FAILURE';

export const REGISTRY_FETCH_REQUEST = 'REGISTRY_FETCH_REQUEST';
export const REGISTRY_FETCH_SUCCESS = 'REGISTRY_FETCH_SUCCESS';
export const REGISTRY_FETCH_FAILURE = 'REGISTRY_FETCH_FAILURE';

export const OWNED_REGISTRIES_FETCH_REQUEST = 'OWNED_REGISTRIES_FETCH_REQUEST';
export const OWNED_REGISTRIES_FETCH_SUCCESS = 'OWNED_REGISTRIES_FETCH_SUCCESS';
export const OWNED_REGISTRIES_FETCH_FAILURE = 'OWNED_REGISTRIES_FETCH_FAILURE';

export const REGISTRIES_FETCH_REQUEST = 'REGISTRIES_FETCH_REQUEST';
export const REGISTRIES_FETCH_SUCCESS = 'REGISTRIES_FETCH_SUCCESS';
export const REGISTRIES_FETCH_FAILURE = 'REGISTRIES_FETCH_FAILURE';

export const REGISTRY_CREATE_EDITING = 'REGISTRY_CREATE_EDITING';
export const REGISTRY_CREATE_REQUEST = 'REGISTRY_CREATE_REQUEST';
export const REGISTRY_CREATE_SUCCESS = 'REGISTRY_CREATE_SUCCESS';
export const REGISTRY_CREATE_FAILURE = 'REGISTRY_CREATE_FAILURE';
export const REGISTRY_CREATE_NAV_NEW = 'REGISTRY_CREATE_NAV_NEW';

// action creators
export const editRegistryFields = (registry, registryFieldName, registryFieldValue) => {
  return {
    type: REGISTRY_EDITING,
    registry,
    registryFieldName,
    registryFieldValue,
  };
};

export const editNewRegistry = (field, value) => {
  return {
    type: REGISTRY_CREATE_EDITING,
    field,
    value,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                               REGISTRY SAVE                                //
////////////////////////////////////////////////////////////////////////////////
export const registrySave = registry => {
  return {
    type: REGISTRY_SAVE_REQUEST,
    registry,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                               REGISTRY FETCH                               //
////////////////////////////////////////////////////////////////////////////////
export const registryFetch = registryID => ({
  type: REGISTRY_FETCH_REQUEST,
  registryID,
});

export const registryFetchDone = registry => ({
  type: REGISTRY_FETCH_SUCCESS,
  registry,
});

export const registryFetchError = error => ({
  type: REGISTRY_FETCH_FAILURE,
  error,
});

////////////////////////////////////////////////////////////////////////////////
//                           OWNED REGISTRIES FETCH                           //
////////////////////////////////////////////////////////////////////////////////
export const ownedRegistriesFetch = () => ({
  type: OWNED_REGISTRIES_FETCH_REQUEST,
});

export const ownedRegistriesFetchDone = ownedRegistries => ({
  type: OWNED_REGISTRIES_FETCH_SUCCESS,
  ownedRegistries,
});

export const ownedRegistriesFetchError = error => ({
  type: OWNED_REGISTRIES_FETCH_FAILURE,
  error,
});

////////////////////////////////////////////////////////////////////////////////
//                              REGISTRIES FETCH                              //
////////////////////////////////////////////////////////////////////////////////
export const registriesFetch = () => ({
  type: REGISTRIES_FETCH_REQUEST,
});

export const registriesFetchDone = list => ({
  type: REGISTRIES_FETCH_SUCCESS,
  list,
});

export const registriesFetchError = error => ({
  type: REGISTRIES_FETCH_FAILURE,
  error,
});

////////////////////////////////////////////////////////////////////////////////
//                                   DELETE                                   //
////////////////////////////////////////////////////////////////////////////////
export const registryDelete = registryID => {
  return {
    type: REGISTRY_DELETE_REQUEST,
    registryID,
  };
};

export const registryDeleteDone = registryID => {
  return {
    type: REGISTRY_DELETE_SUCCESS,
    registryID,
  };
};

export const registryDeleteError = error => {
  return {
    type: REGISTRY_DELETE_FAILURE,
    error,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                                   CREATE                                   //
////////////////////////////////////////////////////////////////////////////////
export const registryCreate = registry => {
  return {
    type: REGISTRY_CREATE_REQUEST,
    registry,
  };
};
export const registryCreateDone = newRegistry => {
  return {
    type: REGISTRY_CREATE_SUCCESS,
    registryID: newRegistry.registryID,
  };
};
export const registryCreateError = error => {
  return {
    type: REGISTRY_CREATE_FAILURE,
    error,
  };
};
export const registryCreateNavigateNew = () => {
  return {
    type: REGISTRY_CREATE_NAV_NEW,
  };
};
