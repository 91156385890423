import { call, put, takeEvery } from 'redux-saga/effects';
import * as authActions from '../actions/authentication';

function* watchFetchList() {
  yield takeEvery(authActions.LOGIN_REQUEST, function* loginSaga({ credentials }) {
    console.log('authenticationSaga: LOGIN_REQUEST : ', process.env.REACT_APP_API_URL);
    try {
      const response = yield fetch(`${process.env.REACT_APP_API_URL}/api/Auth/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      if (response.status === 422) {
        //user not confirmed
        const error = yield response.text();
        console.log(`${response.status} (${response.statusText})\n${error}`);
        yield put(authActions.userNotConfirmed(error));
        //throw new Error(`${error}`);
      } else if (response.status >= 400) {
        const error = yield response.text();
        console.log(`${response.status} (${response.statusText})\n${error}`);
        throw new Error(`${error}`);
      } else {
        const token = yield response.json();
        localStorage.setItem('userToken', token);
        yield put(authActions.loginDone(JSON.parse(token)));
      }
    } catch (err) {
      yield put(authActions.loginError(err));
    }
  });

  yield takeEvery(authActions.SIGNUP_REQUEST, function* signUpSaga({ newUser }) {
    console.log('authenticationSaga: SIGNUP_REQUEST : ', process.env.REACT_APP_API_URL);
    newUser = {
      ...newUser,
      CallBackUrl: `${process.env.REACT_APP_URL}/userConfirmation`,
    };
    try {
      const response = yield fetch(`${process.env.REACT_APP_API_URL}/api/Accounts/signup`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser),
      });
      if (response.status >= 400) {
        const error = yield response.text();
        console.log(`${response.status} (${response.statusText})\n${error}`);
        throw new Error(`${error}`);
      } else {
        yield put(authActions.signUpSuccess());
      }
    } catch (err) {
      yield put(authActions.signUpFailure(err));
    }
  });

  yield takeEvery(authActions.USER_CONFIRMATION_REQUEST, function* userConfirmationSaga({ userID, token }) {
    console.log('authenticationSaga: USER_CONFIRMATION_REQUEST : ', process.env.REACT_APP_API_URL);
    try {
      const response = yield fetch(
        `${process.env.REACT_APP_API_URL}/api/Accounts/confirmUser?userId=${encodeURIComponent(
          userID
        )}&code=${encodeURIComponent(token)}`
      );
      if (response.status >= 400) {
        const error = yield response.text();
        throw new Error(`${error}\n${response.status} (${response.statusText}) `);
      } else {
        yield put(authActions.confirmUserDone());
      }
    } catch (err) {
      console.log('err', err);
      yield put(authActions.confirmUserError(err));
    }
  });

  yield takeEvery(authActions.FORGOT_PASSWORD_REQUEST, function* forgotPasswordSaga({ forgotPasswordFields }) {
    console.log('authenticationSaga: FORGOT_PASSWORD_REQUEST: ', process.env.REACT_APP_API_URL);
    forgotPasswordFields = {
      ...forgotPasswordFields,
      CallBackUrl: `${process.env.REACT_APP_URL}/resetPassword`,
    };
    try {
      yield fetch(`${process.env.REACT_APP_API_URL}/api/Accounts/forgotPassword`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(forgotPasswordFields),
      }).then(response => {
        if (response.status >= 400) {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      });
      yield put(authActions.forgotPasswordDone());
    } catch (err) {
      yield put(authActions.forgotPasswordError(err));
    }
  });

  yield takeEvery(authActions.RESET_PASSWORD_REQUEST, function* resetPasswordSaga({ newPassword, userID, token }) {
    console.log('authenticationSaga: FORGOT_PASSWORD_REQUEST: ', process.env.REACT_APP_API_URL);
    const resetPasswordModel = {
      userID,
      token,
      newPassword: newPassword,
    };
    try {
      const response = yield fetch(`${process.env.REACT_APP_API_URL}/api/Accounts/resetPassword`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(resetPasswordModel),
      });
      if (response.status >= 400) {
        const error = yield response.text();
        throw new Error(`${error}\n${response.status} (${response.statusText}) `);
      } else {
        yield put(authActions.resetPasswordDone());
      }
    } catch (err) {
      console.log('RESET_PASSWORD_REQUEST error:', err);
      yield put(authActions.resetPasswordError(err));
    }
  });

  yield takeEvery(authActions.RESEND_CONFIRMATION_REQUEST, function* resendConfirmationSaga({ email }) {
    console.log('authenticationSaga: RESEND_CONFIRMATION_REQUEST: ', process.env.REACT_APP_API_URL);
    const requestModel = {
      email,
      CallBackUrl: `${process.env.REACT_APP_URL}/userConfirmation`,
    };
    try {
      const response = yield fetch(`${process.env.REACT_APP_API_URL}/api/Accounts/ResendConfirmationEmail`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestModel),
      });
      if (response.status >= 400) {
        const error = yield response.text();
        throw new Error(`${error}\n${response.status} (${response.statusText}) `);
      } else {
        yield put(authActions.resendConfirmationDone());
      }
    } catch (err) {
      console.log('RESEND_CONFIRMATION_REQUEST error:', err);
      yield put(authActions.resendConfirmationFail(err));
    }
  });

  yield takeEvery(authActions.USER_DELETE_REQUEST, function* userDeleteSaga() {
    console.log('authenticationSaga: USER_DELETE_REQUEST: ', process.env.REACT_APP_API_URL);
    const { auth_token } = JSON.parse(localStorage.getItem('userToken'));
    try {
      yield fetch(`${process.env.REACT_APP_API_URL}/api/accounts`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth_token}`,
        },
      }).then(response => {
        if (response.status >= 400) {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      });
      yield put(authActions.forgotPasswordDone());
    } catch (err) {
      yield put(authActions.forgotPasswordError(err));
    }
  });
}

export default [call(watchFetchList)];
