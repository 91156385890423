import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addRegistryItem, addRegistryItemEditField } from '../../actions/registryItems';
import { registryItemCategories, purchaseType } from '../../reducers/referenceData';
import { newRegistryItem } from '../../reducers/registryItems';

import { Link } from 'react-router-dom';
import { registryFetch } from '../../actions/registry';
import { registry } from '../../reducers/registryStore';

const RegistryItemCreate = ({
  match,
  history,
  newRegistryItem,
  registry,
  onEditNewItemField,
  onAddItem,
  registryItemCategories,
  purchaseType,
}) => {
  const registryItemCategoryOptions = [<option key=" " />].concat(
    registryItemCategories.map(ric => (
      <option key={ric.key} value={ric.key}>
        {ric.value}
      </option>
    ))
  );

  const purchaseTypeMap = [<option key=" " />].concat(
    purchaseType.map(ric => (
      <option key={ric.key} value={ric.key}>
        {ric.value}
      </option>
    ))
  );

  if (!registry) {
    history.push(`/registry/${match.params.registryID}`);
    return <div>FRESH LOAD, GOBACK</div>;
  } else
    return (
      <div>
        <br />
        <h1>AddItem:</h1>

        <h2>Add a new item:</h2>

        <div className="form-group">
          <label>Item Description</label>
          <input
            type="text"
            className="form-control"
            placeholder="Description..."
            onChange={({ target }) => {
              onEditNewItemField(registry, 'Description', target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label>Purchase Type</label>
          <select
            type="number"
            value={registry.PurchaseType}
            defaultValue={3}
            className="form-control"
            onChange={({ target }) => {
              onEditNewItemField(registry, 'PurchaseType', target.value);
            }}
          >
            {purchaseTypeMap}
          </select>
        </div>

        <div className="form-group">
          <label>Item Category</label>
          <select
            value={registry.registryItemCategoryID}
            className="form-control"
            onChange={({ target }) => {
              onEditNewItemField(registry, 'registryItemCategoryID', target.value);
            }}
          >
            {registryItemCategoryOptions}
          </select>
        </div>

        <div className="form-group">
          <label>Item URL</label>
          <input
            type="text"
            className="form-control"
            placeholder="url..."
            onChange={({ target }) => {
              onEditNewItemField(registry, 'Url', target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label>Image URL</label>
          <input
            type="text"
            className="form-control"
            placeholder="imageurl..."
            onChange={({ target }) => {
              onEditNewItemField(registry, 'ImageURL', target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label>QuantityRequested</label>
          <input
            defaultValue="1"
            type="number"
            className="form-control"
            placeholder="quantityRequested..."
            onChange={({ target }) => {
              onEditNewItemField(registry, 'QuantityRequested', target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label>Value</label>
          <input
            type="text"
            className="form-control"
            placeholder="value..."
            onChange={({ target }) => {
              onEditNewItemField(registry, 'Value', target.value);
            }}
          />
        </div>
        <br />
        <div className="float-right">
          <Link to={`/registry/${match.params.registryID}`} className="btn btn-secondary mr-2">
            Close
          </Link>
          <button className="btn btn-primary" onClick={() => onAddItem(newRegistryItem)}>
            Add
          </button>
        </div>
      </div>
    );
};

RegistryItemCreate.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  return {
    newRegistryItem: newRegistryItem(state),
    registryItemCategories: registryItemCategories(state),
    registry: registry(state, ownProps.match.params.registryID),
    purchaseType: purchaseType(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchRegistry: registryID => {
    dispatch(registryFetch(registryID));
  },
  onEditNewItemField: (registry, fieldName, newValue) => {
    dispatch(addRegistryItemEditField(registry, fieldName, newValue));
  },
  onAddItem: newRegistryItem => {
    if (newRegistryItem) {
      dispatch(addRegistryItem(newRegistryItem));
      ownProps.history.push(`registry/${ownProps.match.params.registryID}`);
    }
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistryItemCreate)
);
