// constants
export const REGISTRY_SEARCH_REQUEST = 'REGISTRY_SEARCH_REQUEST';
export const REGISTRY_SEARCH_SUCCESS = 'REGISTRY_SEARCH_SUCCESS';
export const REGISTRY_SEARCH_FAILURE = 'REGISTRY_SEARCH_FAILURE';
export const REGISTRY_SEARCH_EDITING = 'REGISTRY_SEARCH_EDITING';

export const REGISTRY_SEARCH_SELECT = 'REGISTRY_SEARCH_SELECT';

// action creators

export const registrySearch = searchParam => {
  return {
    type: REGISTRY_SEARCH_REQUEST,
    searchParam,
  };
};
export const registrySearchEdit = searchParam => {
  return {
    type: REGISTRY_SEARCH_EDITING,
    searchParam,
  };
};
export const registrySearchDone = list => {
  return {
    type: REGISTRY_SEARCH_SUCCESS,
    list,
  };
};
export const registrySearchError = error => {
  return {
    type: REGISTRY_SEARCH_FAILURE,
    error,
  };
};
export const registrySearchSelect = selectedRegistry => {
  return {
    type: REGISTRY_SEARCH_SELECT,
    selectedRegistry,
  };
};
