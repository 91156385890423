import { combineReducers } from 'redux';
import {
  REGISTRY_DELETE_REQUEST,
  REGISTRY_DELETE_SUCCESS,
  REGISTRY_DELETE_FAILURE,
  REGISTRY_EDITING,
  REGISTRIES_FETCH_REQUEST,
  REGISTRIES_FETCH_SUCCESS,
  REGISTRIES_FETCH_FAILURE,
  REGISTRY_FETCH_REQUEST,
  REGISTRY_FETCH_SUCCESS,
  REGISTRY_FETCH_FAILURE,
  REGISTRY_SAVE_REQUEST,
  REGISTRY_SAVE_SUCCESS,
  REGISTRY_SAVE_FAILURE,
  OWNED_REGISTRIES_FETCH_REQUEST,
  OWNED_REGISTRIES_FETCH_SUCCESS,
  OWNED_REGISTRIES_FETCH_FAILURE,
} from '../actions/registry';
import registryItems from './registryItems';
import registrySearch from './registrySearch';
import registryCreate from './registryCreate';
import { REGISTRY_SEARCH_SELECT } from '../actions/registrySearch';

const name = 'registryStore';

// selectors
// TODO: double == as we are comparing string to int
export const currency = ({ [name]: { registryList } }, registryPath) =>
  registryList.registries.find(r => r.registryPath === registryPath).currency;

//Lists
export const registryList = ({ [name]: { registryList } }) => registryList.registries;
export const ownedRegistryList = ({ [name]: { registryList } }) => registryList.ownedRegistries;

// TODO: double == as we are comparing string to int
export const registry = ({ [name]: { registryList } }, registryPath) =>
  registryList.registries.find(r => r.registryPath === registryPath);

// BUSY selectors
export const fetchingRegistryBusy = ({ [name]: { registryList } }) => registryList.registryFetching;
export const fetchingRegistriesBusy = ({ [name]: { registryList } }) => registryList.fetchingList;
export const fetchingOwnedRegistriesBusy = ({ [name]: { registryList } }) => registryList.fetchingOwnedList;
export const deleteingRegistryBusy = ({ [name]: { registryList } }) => registryList.deleteingRegistryBusy;

// ERROR Selectors
export const registryFetchError = ({ [name]: { registryList } }) => registryList.registryFetchError;
export const registriesFetchError = ({ [name]: { registryList } }) => registryList.registriesFetchError;
export const ownedRegistriesFetchError = ({ [name]: { registryList } }) => registryList.ownedRegistriesFetchError;
export const deleteingRegistryError = ({ [name]: { registryList } }) => registryList.deleteingRegistryError;

// reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                      EDIT:                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_EDITING:
      return {
        ...state,
        registries: state.registries.map(registry =>
          registry.registryID === action.registry.registryID
            ? {
                ...registry,
                [action.registryFieldName]: action.registryFieldValue,
              }
            : registry
        ),
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                     DELETE:                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_DELETE_REQUEST:
      return {
        ...state,
        registryDeleteBusy: true,
        registryDeleteError: undefined,
      };
    case REGISTRY_DELETE_SUCCESS:
      return {
        ...state,
        registries: state.registries.filter(registry => registry.registryID !== action.registryID),
        registryDeleteBusy: undefined,
        registryDeleteError: undefined,
      };
    case REGISTRY_DELETE_FAILURE:
      return {
        ...state,
        registryDeleteBusy: undefined,
        registryDeleteError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                REGISTRY FETCH:                                                 //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_FETCH_REQUEST:
      return {
        ...state,
        registryFetching: true,
        registryFetchError: undefined,
      };
    case REGISTRY_FETCH_SUCCESS:
      return {
        ...state,
        registryFetching: undefined,
        registryFetchError: undefined,
        registries: [action.registry],
      };
    case REGISTRY_FETCH_FAILURE:
      return {
        ...state,
        registryFetching: undefined,
        registryFetchError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                               REGISTRIES FETCH:                                                //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRIES_FETCH_REQUEST:
      return {
        ...state,
        fetchingList: true,
        registriesFetchError: undefined,
      };
    case REGISTRIES_FETCH_SUCCESS:
      return {
        ...state,
        fetchingList: undefined,
        registriesFetchError: undefined,
        registries: action.list,
      };
    case REGISTRIES_FETCH_FAILURE:
      return {
        ...state,
        fetchingList: undefined,
        registriesFetchError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                            OWNED REGISTRIES FETCH:                                             //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case OWNED_REGISTRIES_FETCH_REQUEST:
      return {
        ...state,
        ownedRegistriesFetching: true,
        ownedRegistriesFetchError: undefined,
      };
    case OWNED_REGISTRIES_FETCH_SUCCESS:
      return {
        ...state,
        ownedRegistriesFetching: undefined,
        ownedRegistriesFetchError: undefined,
        ownedRegistries: action.ownedRegistries,
      };
    case OWNED_REGISTRIES_FETCH_FAILURE:
      return {
        ...state,
        ownedRegistriesFetching: undefined,
        ownedRegistriesFetchError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                      SAVE:                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_SAVE_REQUEST:
      return {
        ...state,
        savingRegistry: true,
        savingRegistryError: undefined,
      };
    case REGISTRY_SAVE_SUCCESS:
      return {
        ...state,
        savingRegistry: undefined,
        savingRegistryError: undefined,
      };
    case REGISTRY_SAVE_FAILURE:
      return {
        ...state,
        savingRegistry: undefined,
        savingRegistryError: action.error,
      };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                  SEARCH SELECT:                                                //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_SEARCH_SELECT:
      return {
        ...state,
        registries: [
          ...state.registries.filter(registry => registry.registryID !== action.selectedRegistry.registryID),
          action.selectedRegistry,
        ],
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                    DEFAULT:                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
};

export default combineReducers({ registryList: reducer, registryItems, registrySearch, registryCreate });
