import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { registry, registryFetchError, fetchingRegistryBusy } from '../../reducers/registryStore';
import { registryItems } from '../../reducers/registryItems';
import { RegistryItems } from '../RegistryItem';
import { registryComponentSettings } from '../../reducers/settings';
import { fetchRegistryItemsList } from '../../actions/registryItems';
import { toggleRegistryDetails } from '../../actions/settings';
import { registryFetch } from '../../actions/registry';
import Error from '../Error';

const Registry = ({
  match,
  fetchingRegistryBusy,
  registrySettings,
  toggleRegistryDetails,
  fetchingRegistryError,
  onFetchRegistry,
  registryID,
  registry,
}) => {
  if (fetchingRegistryBusy) {
    return <div>loading registry...</div>;
  } else if (fetchingRegistryError) {
    return <Error error={fetchingRegistryError.message} />;
  } else if (!registry.registryID) {
    onFetchRegistry(`${registryID}`);
    return <div>ID NOT IN STORE... should be fetching</div>;
  } else {
    return (
      <div>
        <div onClick={() => toggleRegistryDetails()}>
          <div className="card text-center">
            <div className="card-header">
              <h1>{registry.registryTitle}</h1>
              <i>Click me to expand Details</i>
            </div>
            {/* hide */}
            <div className="card-body" hidden={!registrySettings.showRegistryDetails}>
              <div className="card-body">
                <p>
                  Event Date: {registry.eventDate}
                  <br />
                  ID: {registry.registryID}
                  <br />
                  Description: {registry.registryDescription}
                  <br />
                  OwnerID: {registry.ownerId}
                  <br />
                  Owner: &lt;&lt;&lt; TODO &gt;&gt;&gt;
                  <br />
                  Notes: &lt;&lt;&lt; TODO &gt;&gt;&gt;
                  <br />
                  Pi Chart: &lt;&lt;&lt; TODO: &gt;&gt;&gt;
                  <br />
                  Type (Picture?): {registry.registryTypeID}
                  <br />
                  Created Date: {registry.createdDate}
                  <br />
                  Expires?: {registry.expires ? 'true' : 'false'}
                  <br />
                  Expire Date: {registry.expireDate}
                  <br />
                  Country: {registry.countryCode}
                  <br />
                  Currency: {registry.currency}
                  <br />
                </p>
                <div>
                  <Link to={`${match.url}/edit`} className="btn btn-secondary btn-sm">
                    Edit Registry
                  </Link>

                  <Link to={`${match.url}/admin`} className="btn btn-secondary btn-sm">
                    Registry Admin
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <RegistryItems registryPath={registry.registryPath} />
        <Link
          to={`/registry/${registry.registryID}/addItem`}
          className="d-flex float-right fas fa-plus-circle fa-4x"
          style={{ color: 'SteelBlue', position: 'fixed', bottom: '50px', right: '30px' }}
        />
      </div>
    );
  }
};

Registry.defaultProps = {
  registry: {},
  newRegistryItem: {
    quantityRequested: 1,
  },
};

const mapStateToProps = (state, ownProps) => ({
  fetchingRegistryBusy: fetchingRegistryBusy(state),
  fetchingRegistryError: registryFetchError(state),
  registrySettings: registryComponentSettings(state),
  registryID: ownProps.match.params.registryID,
  registry: registry(state, ownProps.match.params.registryID),

  registryItems: registryItems(state),
});

const mapDispatchToProps = dispatch => ({
  onFetchRegistry: registryID => {
    dispatch(registryFetch(registryID));
  },
  onFetchRegistryItems: registryID => {
    dispatch(fetchRegistryItemsList(registryID));
  },
  toggleRegistryDetails: () => {
    dispatch(toggleRegistryDetails());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Registry)
);
