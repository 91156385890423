const purchaseType = [
  {
    key: 1,
    value: 'Cash',
  },
  {
    key: 2,
    value: 'Offsite',
  },
  {
    key: 3,
    value: 'Both',
  },
];

export default purchaseType;
