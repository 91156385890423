import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { extractRootDomain } from '../../helpers/urlHelper';
import { ImageMap } from '../Shared';
//import Error from "../Error";

const RegistryCard = ({ item, index, selectedCurrency, cardAction }) => {
  const needed = item.quantityRequested - (item.quantityPurchased || 0);
  return (
    <div className="card" onClick={() => (cardAction ? cardAction(item.registryItemID) : undefined)}>
      <div className="card-header">
        <h4 className="card-title mt-1" style={{ height: '60px', overflow: 'hidden' }}>
          &nbsp;
          {item.description}
          &nbsp;
        </h4>
      </div>
      <div
        className="square bg border rounded-top width 20"
        style={{
          backgroundImage: `url(${item.imageUrl || ''})`,
        }}
      >
        <div className="content">
          <br />
        </div>
      </div>
      <div className="card-block">
        <div
          className={`fas ${ImageMap(
            item.registryItemCategoryID
          )} fa-5x fa-border badge badge-secondary m-2 float-left`}
        >
          {' '}
        </div>
        <span className="badge badge-primary float-right m-2">Needed: {needed < 0 ? 0 : needed}</span>
        <br /> <br />
        <div className="card-text" style={{ width: '100%' }}>
          &nbsp;
          <span style={{ fontSize: '13px' }}>{extractRootDomain(item.url)}</span>
          &nbsp;
        </div>
      </div>
      <div className="card-footer">
        <span style={{ fontSize: '23px' }}>
          {selectedCurrency ? selectedCurrency.symbol : '?'}{' '}
          {Number(item.value).toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onPurchaseItem: registryItemID => {
    ownProps.history.push(`/registry/${ownProps.match.params.registryID}/item/${registryItemID}`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistryCard)
);
