import {
  REGISTRY_CREATE_REQUEST,
  REGISTRY_CREATE_SUCCESS,
  REGISTRY_CREATE_FAILURE,
  REGISTRY_CREATE_EDITING,
  REGISTRY_CREATE_NAV_NEW,
} from '../actions/registry';

const name = 'registryStore';

//Insert Selectors
export const newRegistry = ({ [name]: { registryCreate } }) => registryCreate.newRegistry;
export const newRegistryID = ({ [name]: { registryCreate } }) => registryCreate.newRegistryID;
export const newRegistryBusy = ({ [name]: { registryCreate } }) => registryCreate.registryCreateBusy;
export const newRegistryError = ({ [name]: { registryCreate } }) => registryCreate.registryCreateError;

// reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                     EDIT:                                                      //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_CREATE_EDITING:
      return {
        ...state,
        newRegistry: {
          ...state.newRegistry,
          [action.field]: action.value,
        },
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                    INSERT:                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_CREATE_REQUEST:
      return {
        ...state,
        registryCreateBusy: true,
        registryCreateError: undefined,
      };

    case REGISTRY_CREATE_SUCCESS:
      return {
        ...state,
        newRegistry: {},
        newRegistryID: action.registryID,
        registryCreateBusy: undefined,
        registryCreateError: undefined,
      };
    case REGISTRY_CREATE_FAILURE:
      return {
        ...state,
        registryCreateBusy: undefined,
        registryCreateError: action.error,
      };
    case REGISTRY_CREATE_NAV_NEW:
      return {
        ...state,
        newRegistryID: undefined,
        newRegistry: undefined,
        registryCreateBusy: undefined,
        registryCreateError: undefined,
      };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                    DEFAULT:                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
};

export default reducer;
