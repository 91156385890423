// constants
export const REGISTRY_ITEM_DELETE_REQUEST = 'REGISTRY_ITEM_DELETE_REQUEST';
export const REGISTRY_ITEM_DELETE_SUCCESS = 'REGISTRY_ITEM_DELETE_SUCCESS';
export const REGISTRY_ITEM_DELETE_FAILURE = 'REGISTRY_ITEM_DELETE_FAILURE';

export const REGISTRY_ITEMS_FETCH_REQUEST = 'REGISTRY_ITEMS_FETCH_REQUEST';
export const REGISTRY_ITEMS_FETCH_SUCCESS = 'REGISTRY_ITEMS_FETCH_SUCCESS';
export const REGISTRY_ITEMS_FETCH_FAILURE = 'REGISTRY_ITEMS_FETCH_FAILURE';

export const NEW_REGISTRY_ITEM_EDIT_FIELD = 'NEW_REGISTRY_ITEM_EDIT_FIELD';

export const NEW_REGISTRY_ITEM_INSERT_REQUEST = 'NEW_REGISTRY_ITEM_INSERT_REQUEST';
export const NEW_REGISTRY_ITEM_INSERT_SUCCESS = 'NEW_REGISTRY_ITEM_INSERT_SUCCESS';
export const NEW_REGISTRY_ITEM_INSERT_FAILURE = 'NEW_REGISTRY_ITEM_INSERT_FAILURE';

export const REGISTRY_ITEM_EDITING = 'REGISTRY_ITEM_EDITING';
export const REGISTRY_ITEM_CANCEL = 'REGISTRY_ITEM_CANCEL';
export const REGISTRY_ITEM_EDIT_START = 'REGISTRY_ITEM_EDIT_START';

export const REGISTRY_ITEM_EDIT_SAVE_REQUEST = 'REGISTRY_ITEM_EDIT_SAVE_REQUEST';
export const REGISTRY_ITEM_EDIT_SAVE_SUCCESS = 'REGISTRY_ITEM_EDIT_SAVE_SUCCESS';
export const REGISTRY_ITEM_EDIT_SAVE_FAILURE = 'REGISTRY_ITEM_EDIT_SAVE_FAILURE';

// action creators
//EDIT
export const registryItemEditStart = registryItem => ({
  type: REGISTRY_ITEM_EDIT_START,
  registryItem,
});
export const registryItemEdit = (editItemFieldName, editItemFieldValue) => {
  return {
    type: REGISTRY_ITEM_EDITING,
    editItemFieldName,
    editItemFieldValue,
  };
};
export const registryItemEditSave = editingItem => ({
  type: REGISTRY_ITEM_EDIT_SAVE_REQUEST,
  editingItem,
});
export const registryItemEditSaveDone = editingItem => ({
  type: REGISTRY_ITEM_EDIT_SAVE_SUCCESS,
  editingItem,
});
export const registryItemEditSaveError = error => ({
  type: REGISTRY_ITEM_EDIT_SAVE_FAILURE,
  error,
});

export const registryItemEditCancel = () => ({
  type: REGISTRY_ITEM_CANCEL,
});

//DELETE
export const registryItemDelete = (registryID, registryItemID) => {
  return {
    type: REGISTRY_ITEM_DELETE_REQUEST,
    registryItemID,
    registryID,
  };
};
export const registryItemDeleteDone = registryItemID => {
  return {
    type: REGISTRY_ITEM_DELETE_SUCCESS,
    registryItemID,
  };
};
export const registryItemDeleteError = error => {
  return {
    type: REGISTRY_ITEM_DELETE_FAILURE,
    error,
  };
};

//ADD
export const addRegistryItem = newRegistryItem => {
  return {
    type: NEW_REGISTRY_ITEM_INSERT_REQUEST,
    newRegistryItem,
  };
};
export const addRegistryItemEditField = (registry, newItemFieldName, newItemFieldValue) => ({
  type: NEW_REGISTRY_ITEM_EDIT_FIELD,
  registry,
  newItemFieldName,
  newItemFieldValue,
});
export const addRegistryItemDone = newRegistryItem => {
  return {
    type: NEW_REGISTRY_ITEM_INSERT_SUCCESS,
    newRegistryItem,
  };
};
export const addRegistryItemError = error => {
  return {
    type: NEW_REGISTRY_ITEM_INSERT_FAILURE,
    error,
  };
};

//FETCH
export const fetchRegistryItemsList = registryID => {
  return {
    type: REGISTRY_ITEMS_FETCH_REQUEST,
    registryID,
  };
};
export const fetchRegistryItemsListDone = (registryID, list) => {
  return {
    type: REGISTRY_ITEMS_FETCH_SUCCESS,
    registryID,
    list,
  };
};
export const fetchRegistryItemsListError = error => ({
  type: REGISTRY_ITEMS_FETCH_FAILURE,
  error,
});
//
