export const ImageMap = categoryID => {
  switch (categoryID) {
    case 'H':
      return 'fa-mars';
    case 'S':
      return 'fa-venus';
    case 'T':
      return 'fa-child';
    case 'B':
      return 'fa-grin-stars';
    default:
      return 'fa-question';
  }
};
