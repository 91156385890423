import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  registryItemDelete,
  registryItemEdit,
  registryItemEditCancel,
  registryItemEditSave,
} from '../../actions/registryItems';
import { registryItemCategories, purchaseType } from '../../reducers/referenceData';
import { editingItem } from '../../reducers/registryItems';

const RegistryItemEdit = ({
  onDeleteRegistryItem,
  editingItem,
  onRegistryItemEdit,
  onEditItemCancel,
  onEditItemSave,
  registryItemCategories,
  purchaseType,
}) => {
  const registryItemCategoryOptions = [<option key=" " />].concat(
    registryItemCategories.map(ric => (
      <option key={ric.key} value={ric.key}>
        {ric.value}
      </option>
    ))
  );

  const purchaseTypeMap = [<option key=" " />].concat(
    purchaseType.map(ric => (
      <option key={ric.key} value={ric.key}>
        {ric.value}
      </option>
    ))
  );

  return (
    <div>
      <h2>Edting a Registry Item:</h2>
      <p>{editingItem.description}</p>

      <div className="form-group">
        <label>Item Description</label>
        <input
          value={editingItem.description || ''}
          type="text"
          className="form-control"
          placeholder="Description..."
          onChange={({ target }) => {
            onRegistryItemEdit('description', target.value);
          }}
        />
      </div>

      <div className="form-group">
        <label>Purchase Type</label>
        <select
          value={editingItem.purchaseType}
          type="number"
          className="form-control"
          onChange={({ target }) => {
            onRegistryItemEdit('purchaseType', target.value);
          }}
        >
          {purchaseTypeMap}
        </select>
      </div>

      <div className="form-group">
        <label>Item Category</label>
        <select
          value={editingItem.registryItemCategoryID}
          className="form-control"
          onChange={({ target }) => {
            onRegistryItemEdit('registryItemCategoryID', target.value);
          }}
        >
          {registryItemCategoryOptions}
        </select>
      </div>

      <div className="form-group">
        <label>Item URL</label>
        <input
          value={editingItem.url || ''}
          type="text"
          className="form-control"
          placeholder="url..."
          onChange={({ target }) => {
            onRegistryItemEdit('url', target.value);
          }}
        />
      </div>

      <div className="form-group">
        <label>Image URL</label>
        <input
          value={editingItem.imageUrl || ''}
          type="text"
          className="form-control"
          placeholder="imageurl..."
          onChange={({ target }) => {
            onRegistryItemEdit('imageUrl', target.value);
          }}
        />
      </div>

      <div className="form-group">
        <label>Quantity Requested</label>
        <input
          value={editingItem.quantityRequested || 0}
          type="number"
          className="form-control"
          placeholder="quantityRequested..."
          onChange={({ target }) => {
            onRegistryItemEdit('quantityRequested', target.value);
          }}
        />
      </div>

      <div className="form-group">
        <label>Value</label>
        <input
          value={editingItem.value || 0}
          type="number"
          className="form-control"
          placeholder="value..."
          onChange={({ target }) => {
            onRegistryItemEdit('value', target.value);
          }}
        />
      </div>

      <br />
      <button className="btn btn-secondary" onClick={() => onEditItemCancel()}>
        CANCEL
      </button>
      <button className="btn btn-primary" onClick={() => onEditItemSave(editingItem)}>
        SAVE
      </button>
      <button
        className="btn btn-danger"
        onClick={() => onDeleteRegistryItem(editingItem.registryID, editingItem.registryItemID)}
      >
        DELETE
      </button>
    </div>
  );
};

const mapStateToProps = state => ({
  editingItem: editingItem(state),
  registryItemCategories: registryItemCategories(state),
  purchaseType: purchaseType(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditItemCancel: () => {
    dispatch(registryItemEditCancel());
    ownProps.history.push(`/registry/${ownProps.match.params.registryID}`);
  },
  onEditItemSave: editingItem => {
    console.log('editing Item to save', editingItem);
    dispatch(registryItemEditSave(editingItem));
    ownProps.history.push(`/registry/${ownProps.match.params.registryID}`);
  },
  onDeleteRegistryItem: (registryID, registryItemID) => {
    dispatch(registryItemDelete(registryID, registryItemID));
    ownProps.history.push(`/registry/${registryID}`);
  },
  onRegistryItemEdit: (editItemFieldName, editItemFieldValue) => {
    dispatch(registryItemEdit(editItemFieldName, editItemFieldValue));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistryItemEdit)
);
