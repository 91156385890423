import React from 'react';
import CustomMenuWrap from './CustomMenuWrap';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { isLoggedIn } from '../reducers/authentication';
import { connect } from 'react-redux';

const CustomMenu = ({ isLoggedIn }) => {
  return (
    <CustomMenuWrap wait={20}>
      <Menu id="slide" pageWrapId={'page-wrap'} outerContainerId={'outer-container'}>
        <Link id="home" className="menu-item" to="/">
          <i className="fas fa-home" />
          <span>Home</span>
        </Link>

        {/* <Link id="registries" className="menu-item" to="/create">
          <i className="fas fa-plus-circle" />
          <span>Create Registry</span>
        </Link> */}

        <Link id="registries" className="menu-item" to="/registries">
          <i className="fas fa-clipboard-list" />
          <span>My Registries</span>
        </Link>

        <Link id="about" className="menu-item" to="/about">
          <i className="fas fa-info-circle" />
          <span>About</span>
        </Link>

        <Link id="contact" className="menu-item" to="/contact">
          <i className="fas fa-phone" />
          <span>Contact</span>
        </Link>

        {isLoggedIn ? (
          <Link className="menu-item--small" to="/account">
            <i className="fas fa-sliders-h" />
            <span>My Account</span>
          </Link>
        ) : (
          <Link className="menu-item--small" to="/signUp">
            <i className="fas fa-user-plus" />
            <span>Signup</span>
          </Link>
        )}
        {isLoggedIn ? (
          <Link className="menu-item--small" to="/logout">
            <i className="fas fa-sign-out-alt" />
            <span>Logout </span>
          </Link>
        ) : (
          <Link className="menu-item--small" to="/login">
            <i className="fas fa-sign-in-alt" />
            <span>Login</span>
          </Link>
        )}
      </Menu>
    </CustomMenuWrap>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps)(CustomMenu);
