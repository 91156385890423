import configureStore from './configureStore';
import referenceData from './referenceData';
import registryStore from './registryStore';
import authentication from './authentication';
import settings from './settings';

const defaultStore = configureStore({
  authentication,
  registryStore,
  referenceData,
  settings,
});

export default defaultStore;
