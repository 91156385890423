// actions
export const FETCH_REFERENCE_DATA_REQUEST = 'FETCH_REFERENCE_DATA_REQUEST';
export const FETCH_REFERENCE_DATA_SUCCESS = 'FETCH_REFERENCE_DATA_SUCCESS';
export const FETCH_REFERENCE_DATA_FAILURE = 'FETCH_REFERENCE_DATA_FAILURE';

// action creators
export const fetchReferenceDataList = () => ({
  type: FETCH_REFERENCE_DATA_REQUEST,
});

export const fetchReferenceDataListDone = list => ({
  type: FETCH_REFERENCE_DATA_SUCCESS,
  list,
});

export const fetchReferenceDataListError = error => ({
  type: FETCH_REFERENCE_DATA_FAILURE,
  error,
});
