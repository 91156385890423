import * as authActions from '../actions/authentication';

const name = 'authentication';

// selectors
export const token = ({ [name]: { token } }) => token;
export const newUser = ({ [name]: { newUser } }) => newUser;
export const credentials = ({ [name]: { credentials } }) => credentials;
export const passwordResetFields = ({ [name]: { passwordReset } }) => passwordReset;
export const passwordForgotFields = ({ [name]: { passwordForgot } }) => passwordForgot;

// login status
export const loginBusy = ({ [name]: { loginBusy } }) => loginBusy;
export const loginError = ({ [name]: { loginError } }) => loginError;
export const isLoggedIn = ({ [name]: { isLoggedIn } }) => isLoggedIn;
export const loginNotConfirmed = ({ [name]: { loginNotConfirmed } }) => loginNotConfirmed;

// signUp status
export const signUpBusy = ({ [name]: { signUpBusy } }) => signUpBusy;
export const signUpDone = ({ [name]: { signUpDone } }) => signUpDone;
export const signUpError = ({ [name]: { signUpError } }) => signUpError;

// forgotPassword status
export const forgotPasswordBusy = ({ [name]: { forgotPasswordBusy } }) => forgotPasswordBusy;
export const forgotPasswordError = ({ [name]: { forgotPasswordError } }) => forgotPasswordError;
export const forgotPasswordDone = ({ [name]: { forgotPasswordDone } }) => forgotPasswordDone;

// resetPassword status
export const resetPasswordBusy = ({ [name]: { resetPasswordBusy } }) => resetPasswordBusy;
export const resetPasswordError = ({ [name]: { resetPasswordError } }) => resetPasswordError;
export const resetPasswordDone = ({ [name]: { resetPasswordDone } }) => resetPasswordDone;

// delete status
export const deleteBusy = ({ [name]: { deleteBusy } }) => deleteBusy;
export const deleteDone = ({ [name]: { deleteDone } }) => deleteDone;
export const deleteError = ({ [name]: { deleteError } }) => deleteError;

// Confirmation status
export const confirmationBusy = ({ [name]: { confirmationBusy } }) => confirmationBusy;
export const confirmationDone = ({ [name]: { confirmationDone } }) => confirmationDone;
export const confirmationError = ({ [name]: { confirmationError } }) => confirmationError;

// Resend Confirmation status
export const resendConfirmationBusy = ({ [name]: { resendConfirmationBusy } }) => resendConfirmationBusy;
export const resendConfirmationDone = ({ [name]: { resendConfirmationDone } }) => resendConfirmationDone;
export const resendConfirmationError = ({ [name]: { resendConfirmationError } }) => resendConfirmationError;

// reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                               USER CONFIRMATION                                                //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case authActions.USER_CONFIRMATION_REQUEST:
      return {
        ...state,
        confirmation: {
          userID: action.userID,
          token: action.token,
        },
        confirmationBusy: true,
        confirmationDone: undefined,
        confirmationError: undefined,
      };
    case authActions.USER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        confirmationBusy: undefined,
        confirmationDone: true,
        confirmationError: undefined,
      };
    case authActions.USER_CONFIRMATION_FAILURE:
      return {
        ...state,
        confirmationBusy: undefined,
        confirmationDone: undefined,
        confirmationError: action.error,
      };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                               RESEND CONFIRMATION                                              //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case authActions.RESEND_CONFIRMATION_REQUEST:
      return {
        ...state,
        resendConfirmationBusy: true,
        resendConfirmationDone: undefined,
        resendConfirmationError: undefined,
      };
    case authActions.RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        resendConfirmationBusy: undefined,
        resendConfirmationDone: true,
        resendConfirmationError: undefined,
      };
    case authActions.RESEND_CONFIRMATION_FAILURE:
      return {
        ...state,
        resendConfirmationBusy: undefined,
        resendConfirmationDone: undefined,
        resendConfirmationError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                     SIGNUP                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case authActions.SIGNUP_EDITING:
      return {
        ...state,
        newUser: {
          ...state.newUser,
          [action.field]: action.value,
        },
      };

    case authActions.SIGNUP_REQUEST:
      return {
        ...state,
        signUpBusy: true,
        signUpDone: undefined,
        signUpError: undefined,
      };
    case authActions.SIGNUP_SUCCESS:
      return {
        state: { state: { newUser, ...state } },
        signUpBusy: undefined,
        signUpDone: true,
        signUpError: undefined,
      };
    case authActions.SIGNUP_FAILURE:
      return {
        ...state,
        signUpBusy: undefined,
        signUpDone: undefined,
        signUpError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                     LOGIN                                                      //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case authActions.CREDENTIALS_EDITING:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          [action.field]: action.value,
        },
      };

    case authActions.LOGIN_REQUEST:
      return {
        ...state,
        loginBusy: true,
        loginError: undefined,
        isLoggedIn: undefined,
        loginNotConfirmed: undefined,
      };
    case authActions.LOGOUT_REQUEST:
      return {};

    case authActions.LOGIN_SUCCESS:
      return {
        ...state,
        credentials: undefined,
        token: action.token,
        loginBusy: undefined,
        loginError: undefined,
        isLoggedIn: true,
        loginNotConfirmed: undefined,
      };
    case authActions.LOGIN_FAILURE:
      return {
        ...state,
        loginBusy: undefined,
        loginError: action.error,
        isLoggedIn: undefined,
        loginNotConfirmed: undefined,
      };
    case authActions.LOGIN_CONFIRM:
      return {
        ...state,
        loginBusy: undefined,
        loginError: undefined,
        isLoggedIn: undefined,
        loginNotConfirmed: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                 FORGOT PASSWORD                                                //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case authActions.FORGOT_PASSWORD_EDITING:
      return {
        ...state,
        passwordForgot: {
          ...state.passwordForgot,
          [action.field]: action.value,
        },
      };

    case authActions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordBusy: true,
        forgotPasswordError: undefined,
        forgotPasswordDone: undefined,
      };
    case authActions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordForgot: undefined,
        forgotPasswordBusy: undefined,
        forgotPasswordError: undefined,
        forgotPasswordDone: true,
      };
    case authActions.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordBusy: undefined,
        forgotPasswordError: action.error,
        forgotPasswordDone: undefined,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                 RESET PASSWORD                                                 //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case authActions.RESET_PASSWORD_EDITING:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          [action.field]: action.value,
        },
      };

    case authActions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordBusy: true,
        resetPasswordError: undefined,
        resetPasswordDone: undefined,
      };
    case authActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: undefined,
        resetPasswordBusy: undefined,
        resetPasswordError: undefined,
        resetPasswordDone: true,
      };
    case authActions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordBusy: undefined,
        resetPasswordError: action.error,
        resetPasswordDone: undefined,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                  DELETE USER                                                   //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case authActions.USER_DELETE_REQUEST:
      return {
        ...state,
        deleteBusy: true,
        deleteDone: undefined,
        deleteError: undefined,
      };
    case authActions.USER_DELETE_SUCCESS:
      return {
        ...state,
        deleteBusy: undefined,
        deleteDone: true,
        deleteError: undefined,
      };
    case authActions.USER_DELETE_FAILURE:
      return {
        ...state,
        deleteBusy: undefined,
        deleteDone: undefined,
        deleteError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                    DEFAULT                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
};

export default reducer;
