import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About:</h1>
      <p>This was created for my wedding. Nothing in South Africa could handle adding content from multiple shops.</p>
      <h3>Places we wanted to shop:</h3>
      <ul>
        <li>
          <a href="https://www.takealot.com" target="_blank" rel="noopener noreferrer">
            Takealot
          </a>
        </li>
      </ul>
      <p>To solve my problem, Online Gift Registry was made. I hope that it can help you too.</p>
      <p>
        Please see our <a href="https://trello.com/b/fdQo3LrQ/online-gift-registry">Trello Board</a> for what we are
        busy with and features that we are adding
      </p>
      <div className="footer navbar-bottom">
        (c 2018) api_url=
        {process.env.REACT_APP_API_URL} Last Upate: 2018/10/05
      </div>
    </div>
  );
};

export default About;
