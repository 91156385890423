import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { loginRequest, editLoginFields, resendConfirmation } from '../../actions/authentication';
import {
  credentials,
  loginBusy,
  loginError,
  isLoggedIn,
  loginNotConfirmed,
  resendConfirmationBusy,
  resendConfirmationError,
  resendConfirmationDone,
} from '../../reducers/authentication';

const Login = ({
  location,
  onEditCredentials,
  credentials,
  onLogin,
  loginBusy,
  loginError,
  isLoggedIn,
  loginNotConfirmed,
  onRequestConfirmAccount,
  resendConfirmationBusy,
  resendConfirmationDone,
  resendConfirmationError,
}) => {
  const { returnUrl } = queryString.parse(location.search);
  if (loginNotConfirmed) {
    console.log('loginNotConfirmed ', loginNotConfirmed);
    return (
      <div>
        <h1>User has not been confirmed</h1>
        {loginNotConfirmed.message} <br />
        {resendConfirmationDone ? (
          <div>
            <h2>Success!</h2>
            <p>We've sent you a new confirmation email, check your mail!</p>
            <p>You can close this tab...</p>
          </div>
        ) : (
          <div>
            <p>Please press the button below to send a confirmation email to: "{`${credentials.username}`}"</p>
            <button className="btn btn-primary" onClick={() => onRequestConfirmAccount(credentials.username)}>
              Try again
            </button>
          </div>
        )}
        <br />
        <br />
        <p>Busy: {resendConfirmationBusy ? 'resendConfirmationBusy' : '-'}</p>
        <p>Error: {resendConfirmationError ? resendConfirmationError.message : '-'}</p>
      </div>
    );
  } else if (isLoggedIn) {
    return (
      <div>
        <Redirect to={returnUrl ? decodeURI(returnUrl) : '/'} />
        Success! Taking you home
      </div>
    );
  } else
    return (
      <div>
        <br />
        <h1>Login:</h1>
        <i>
          Please enter your credentials below
          <br />
          <br />
        </i>

        <div className="container border rounded" style={{ maxWidth: '400px' }}>
          <br />
          <div className="row justify-content-md-center">
            <div className="col-sm">
              <div className="form-group">
                <label>Email Address:</label>
                <input
                  value={credentials.username || ''}
                  className="form-control"
                  type="text"
                  name="username"
                  id="username"
                  onKeyDown={({ keyCode }) => (keyCode === 13 ? onLogin(credentials) : {})}
                  onChange={({ target }) => {
                    onEditCredentials('username', target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label>Password:</label>
                <input
                  value={credentials.password || ''}
                  type="password"
                  name="password"
                  id="password"
                  className="form-control"
                  onKeyDown={({ keyCode }) => (keyCode === 13 ? onLogin(credentials) : {})}
                  onChange={({ target }) => {
                    onEditCredentials('password', target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <button className="btn btn-primary" onClick={() => onLogin(credentials)}>
                  Login
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
          <Link to="/forgotPassword">Forgot Password:</Link> <br />
          <Link to="/signUp">Signup:</Link>
          <p>Busy: {loginBusy ? 'loginBusy' : '-'}</p>
          <p>Error: {loginError ? loginError.message : '-'}</p>
        </div>
      </div>
    );
};

Login.defaultProps = {
  credentials: {},
};

const mapStateToProps = (state, ownProps) => ({
  credentials: credentials(state),
  loginNotConfirmed: loginNotConfirmed(state),
  loginBusy: loginBusy(state),
  loginError: loginError(state),
  isLoggedIn: isLoggedIn(state),

  resendConfirmationBusy: resendConfirmationBusy(state),
  resendConfirmationDone: resendConfirmationDone(state),
  resendConfirmationError: resendConfirmationError(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onRequestConfirmAccount: emailAddress => {
    dispatch(resendConfirmation(emailAddress));
  },
  onLogin: credentials => {
    dispatch(loginRequest(credentials));
  },
  onEditCredentials: (editItemFieldName, editItemFieldValue) => {
    dispatch(editLoginFields(editItemFieldName, editItemFieldValue));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
