const currencies = [
  {
    currencyCode: 'ZAR',
    symbol: 'R',
    description: 'South African Rand',
  },
  {
    currencyCode: 'USD',
    symbol: '$',
    description: 'US Dollar',
  },
];

export default currencies;
