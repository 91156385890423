import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
  passwordResetFields,
  resetPasswordBusy,
  resetPasswordError,
  resetPasswordDone,
} from '../../reducers/authentication';
import { resetPasswordEdit, resetPassword } from '../../actions/authentication';

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
const ResetPassword = ({
  onEditResetPassword,
  onResetPassword,
  location,
  passwordResetFields,
  resetPasswordBusy,
  resetPasswordError,
  resetPasswordDone,
  onGoToLogin,
}) => {
  //https://tylermcginnis.com/react-router-query-strings/
  const values = queryString.parse(location.search);
  if (resetPasswordDone) {
    return (
      <div>
        <h1>Success</h1>
        <p>Click here to login with the new password</p>
        <button className="btn btn-primary" onClick={() => onGoToLogin()}>
          Login
        </button>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Reset Password:</h1>
        <p>Please enter your new password:</p>

        <div className="row justify-content-md-center">
          <div className="col-sm">
            <div className="form-group">
              <label>Password:</label>
              <input
                value={passwordResetFields.newPassword || ''}
                className="form-control"
                type="password"
                onKeyDown={({ keyCode }) => (keyCode === 13 ? onResetPassword(passwordResetFields) : {})}
                onChange={({ target }) => {
                  onEditResetPassword('newPassword', target.value);
                }}
              />
            </div>

            <div className="form-group">
              <label>Re-enter password:</label>
              <input
                disabled={true}
                value={passwordResetFields.newPasswordConfirm || ''}
                className="form-control"
                type="password"
                onKeyDown={({ keyCode }) => (keyCode === 13 ? onResetPassword(passwordResetFields) : {})}
                onChange={({ target }) => {
                  onEditResetPassword('newPasswordConfirm', target.value);
                }}
              />
            </div>
          </div>
        </div>

        <button
          className="btn btn-primary"
          onClick={() => onResetPassword(passwordResetFields.newPassword, values.userID, values.token)}
        >
          Reset Password
        </button>
        <br />

        <p>Busy: {resetPasswordBusy ? 'resetPasswordBusy' : '-'}</p>
        <p>Error: {resetPasswordError ? resetPasswordError.message : '-'}</p>
      </div>
    );
  }
};

ResetPassword.defaultProps = { passwordResetFields };

const mapStateToProps = (state, ownProps) => ({
  passwordResetFields: passwordResetFields(state),
  resetPasswordError: resetPasswordError(state),
  resetPasswordBusy: resetPasswordBusy(state),
  resetPasswordDone: resetPasswordDone(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditResetPassword: (field, value) => {
    dispatch(resetPasswordEdit(field, value));
  },
  onResetPassword: (newPassword, userID, token) => {
    dispatch(resetPassword(newPassword, userID, token));
  },
  onGoToLogin: () => {
    ownProps.history.push(`/login`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword)
);
