import { call, put, takeEvery } from 'redux-saga/effects';
import * as offsiteTransactionActions from '../actions/offsiteTransaction';

function* watchFetchList() {
  yield takeEvery(offsiteTransactionActions.NEW_OFFSITE_TRANSACTION_REQUEST, function* offsiteTransactionCreateSaga({
    registryPath,
    registryItemID,
    quantity,
  }) {
    console.log('offsiteTransactionSaga: NEW_OFFSITE_TRANSACTION_REQUEST : ', process.env.REACT_APP_API_URL);
    const { auth_token } = JSON.parse(localStorage.getItem('userToken'));

    const PostTransactionItem = {
      registryPath,
      registryItemID,
      quantity,
    };

    console.log('PostTransactionItem: ', PostTransactionItem);
    try {
      const response = yield fetch(`${process.env.REACT_APP_API_URL}/api/transaction/offsite`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth_token}`,
        },
        body: JSON.stringify(PostTransactionItem),
      });
      if (response.status >= 400) {
        const error = yield response.text();
        console.log(`${response.status} (${response.statusText})\n${error}`);
        throw new Error(`${error}`);
      } else {
        yield put(offsiteTransactionActions.newOffsiteTransactionDone());
      }
    } catch (err) {
      yield put(offsiteTransactionActions.newOffsiteTransactionError(err));
    }
  });
}
export default [call(watchFetchList)];
