import { call, put, takeEvery } from 'redux-saga/effects';
import * as registryActions from '../actions/registry';

function* watchFetchList() {
  yield takeEvery(registryActions.REGISTRIES_FETCH_REQUEST, function* fetchListSaga() {
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/api/registry`);
      const registryList = yield response.json();
      yield put(registryActions.registriesFetchDone(registryList));
    } catch (err) {
      yield put(registryActions.registriesFetchError(err));
    }
  });

  yield takeEvery(registryActions.OWNED_REGISTRIES_FETCH_REQUEST, function* fetchListSaga() {
    const { auth_token } = JSON.parse(localStorage.getItem('userToken'));
    try {
      const response = yield fetch(`${process.env.REACT_APP_API_URL}/api/registry/owned`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth_token}`,
        },
      });
      const registryList = yield response.json();
      yield put(registryActions.ownedRegistriesFetchDone(registryList));
    } catch (err) {
      yield put(registryActions.ownedRegistriesFetchError(err));
    }
  });

  yield takeEvery(registryActions.REGISTRY_FETCH_REQUEST, function* fetchListSaga({ registryID }) {
    console.log('REGISTRY_FETCH_REQUESTsage:', registryID);
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/api/registry/${registryID}`);
      if (response.ok) {
        const registry = yield response.json();
        console.log('REGISTRY_FETCH_REQUESTsage: done');

        yield put(registryActions.registryFetchDone(registry));
      } else {
        console.log('REGISTRY_FETCH_REQUESTsage: else');
        yield put(registryActions.registryFetchError(`${response.status}: ${response.statusText}`));
      }
    } catch (err) {
      console.log('REGISTRY_FETCH_REQUESTsage: err');
      yield put(registryActions.registryFetchError(err));
    }
  });

  yield takeEvery(registryActions.REGISTRY_SAVE_REQUEST, function* registryEditSage({ registry }) {
    const { registryPath, ...rest } = registry;
    try {
      fetch(`${process.env.REACT_APP_API_URL}/api/registry/${registryPath}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...rest }),
      });
    } catch (err) {
      yield put({ type: registryActions.REGISTRY_SAVE_FAILURE, message: err.message });
    }
  });

  yield takeEvery(registryActions.REGISTRY_DELETE_REQUEST, function* deleteRegistrySaga({ registryID }) {
    var { auth_token } = JSON.parse(localStorage.getItem('userToken'));
    try {
      const response = yield call(fetch, `${process.env.REACT_APP_API_URL}/api/registry/${registryID}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });
      const status = yield response;

      if (status.status >= 400) {
        throw new Error(`${response.status} (${response.statusText})`);
      } else {
        yield put(registryActions.registryDeleteDone(registryID));
      }
    } catch (err) {
      yield put(registryActions.registryDeleteError(err));
    }
  });

  yield takeEvery(registryActions.REGISTRY_CREATE_REQUEST, function* registryCreateSaga({
    registry: { registryID, ...registry },
  }) {
    var { auth_token } = JSON.parse(localStorage.getItem('userToken'));
    try {
      const postedRegistry = yield fetch(`${process.env.REACT_APP_API_URL}/api/registry`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth_token}`,
        },
        body: JSON.stringify(registry),
      }).then(response => {
        if (response.status >= 400) {
          throw new Error(`${response.status} (${response.statusText})`);
        } else {
          return response.json();
        }
      });
      yield put(registryActions.registryCreateDone(postedRegistry));
    } catch (err) {
      yield put(registryActions.registryCreateError(err));
    }
  });
}

export default [call(watchFetchList)];
