import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { forgotPasswordEdit, forgotPassword } from '../../actions/authentication';
import {
  forgotPasswordBusy,
  forgotPasswordError,
  passwordForgotFields,
  forgotPasswordDone,
} from '../../reducers/authentication';

const ForgotPassword = ({
  location,
  forgotPasswordBusy,
  forgotPasswordDone,
  forgotPasswordError,
  passwordForgotFields,
  onEditForgotPassword,
  onForgotPassword,
  onComplete,
}) => {
  if (forgotPasswordDone) {
    return (
      <div>
        <h1>Forgot Password:</h1>
        <p>You have requested a password reset.</p>
        <p>If you have an account with us, you will receive an email with instructions to reset your password.</p>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Forgot Password:</h1>
        <p>Please enter your email address below to reset it:</p>

        <div className="container border rounded" style={{ maxWidth: '400px' }}>
          <br />
          <div className="row justify-content-md-center">
            <div className="col-sm">
              <div className="form-group">
                <label>Email Address:</label>
                <input
                  value={passwordForgotFields.email || ''}
                  className="form-control"
                  onChange={({ target }) => {
                    onEditForgotPassword('email', target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <button className="btn btn-primary" onClick={() => onForgotPassword(passwordForgotFields)}>
            Submit
          </button>
          <p>Busy: {forgotPasswordBusy ? 'forgotPasswordBusy' : '-'}</p>
          <p>Error: {forgotPasswordError ? forgotPasswordError.message : '-'}</p>
        </div>
      </div>
    );
  }
};

ForgotPassword.defaultProps = { passwordForgotFields };

const mapStateToProps = (state, ownProps) => ({
  forgotPasswordError: forgotPasswordError(state),
  forgotPasswordBusy: forgotPasswordBusy(state),
  forgotPasswordDone: forgotPasswordDone(state),
  passwordForgotFields: passwordForgotFields(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditForgotPassword: (field, value) => {
    dispatch(forgotPasswordEdit(field, value));
  },
  onForgotPassword: passwordForgotFields => {
    dispatch(forgotPassword(passwordForgotFields));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotPassword)
);
