import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { registryItemEditStart } from '../../actions/registryItems';
import RegistryCard from './RegistryCard';
import { registry } from '../../reducers/registryStore';

//import Error from "../Error";

const RegistryItem = ({ item, index, selectedCurrency, onEditItemDetails, onPurchaseItem, registry }) => {
  const { id } = JSON.parse(localStorage.getItem('userToken')) || {};
  const { ownerId } = registry;

  return (
    <div className="col-sm-4" key={index}>
      <RegistryCard item={item} selectedCurrency={selectedCurrency} cardAction={onPurchaseItem} />{' '}
      {ownerId === id ? (
        <button
          className={`fas fa-wrench fa-5x fa-border badge badge-secondary m-2`}
          onClick={() => onEditItemDetails(item)}
        >
          Edit
        </button>
      ) : (
        <div />
      )}
      <br />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  registry: registry(state, ownProps.match.params.registryID),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onPurchaseItem: registryItemID => {
    ownProps.history.push(`/registry/${ownProps.match.params.registryID}/item/${registryItemID}`);
  },
  onEditItemDetails: registryItem => {
    dispatch(registryItemEditStart(registryItem));
    ownProps.history.push(`/registry/${ownProps.match.params.registryID}/item/${registryItem.registryItemID}/edit`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistryItem)
);
