import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import { registryCreate, editNewRegistry, registryCreateNavigateNew } from '../../actions/registry';
import { newRegistry, newRegistryBusy, newRegistryError, newRegistryID } from '../../reducers/registryCreate';
import { withRouter } from 'react-router-dom';
import { registryTypes } from '../../reducers/referenceData';
import { isLoggedIn } from '../../reducers/authentication';
import NotLoggedIn from '../NotLoggedIn';
import Error from '../Error';

export const RegistryPropType = {
  registryTitle: PropTypes.string.isRequired,
  country: PropTypes.string,
};

const RegistryCreate = ({
  location,
  newRegistry,
  newRegistryID,
  onEditNewRegistry,
  onRegistryCreate,
  registryTypes,
  onRegistryCreateNavigate,
  newRegistryBusy,
  newRegistryError,
  isLoggedIn,
}) => {
  let registryTypeOptions = [<option key=" " />].concat(
    registryTypes.map(rt => (
      <option key={rt.key} value={rt.key}>
        {rt.value}
      </option>
    ))
  );
  if (newRegistryBusy) {
    return <div>Saving...</div>;
  }
  if (!isLoggedIn) {
    return (
      <div>
        <NotLoggedIn returnUrl={encodeURI(location.pathname)} />
      </div>
    );
  } else if (newRegistryID) {
    onRegistryCreateNavigate(newRegistryID);
    return (
      <div>
        <p>New Registry ID {newRegistryID}</p>
      </div>
    );
  } else {
    return (
      <div>
        {newRegistryError ? <Error error={newRegistryError.message} /> : ''}
        <div className="registry-table">
          <h1>Create a Registry</h1>
          <div className="border">
            <div className="form-group">
              <label>Title:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Registry Title..."
                value={newRegistry.registryTitle || ''}
                onChange={({ target }) => onEditNewRegistry('registryTitle', target.value)}
              />
            </div>

            <div className="form-group">
              <label>registryPath</label>
              <input
                type="text"
                className="form-control"
                placeholder="registryPath..."
                value={newRegistry.registryPath || ''}
                onChange={({ target }) => onEditNewRegistry('registryPath', target.value)}
              />
            </div>

            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Registry Description..."
                value={newRegistry.registryDescription || ''}
                onChange={({ target }) => onEditNewRegistry('registryDescription', target.value)}
              />
            </div>

            <div className="form-group">
              <label>Registry Type</label>
              <select
                value={newRegistry.registryTypeID || ' '}
                className="form-control"
                onChange={({ target }) => onEditNewRegistry('registryTypeID', target.value)}
              >
                {registryTypeOptions}
              </select>
            </div>

            <button className="btn btn-primary float-right m-2" onClick={() => onRegistryCreate(newRegistry)}>
              Add
            </button>
            <br />
          </div>
        </div>
        <p>Busy: {newRegistryBusy ? 'loginBusy' : '-'}</p>
        <p>Error: {newRegistryError ? newRegistryError.message : '-'}</p>
      </div>
    );
  }
};

RegistryCreate.defaultProps = {
  newRegistry: {},
};

const mapStateToProps = state => ({
  newRegistry: newRegistry(state),
  newRegistryID: newRegistryID(state),
  newRegistryBusy: newRegistryBusy(state),
  newRegistryError: newRegistryError(state),
  registryTypes: registryTypes(state),
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditNewRegistry: (field, value) => {
    dispatch(editNewRegistry(field, value));
  },
  onRegistryCreate: newRegistry => {
    dispatch(registryCreate(newRegistry));
  },
  onRegistryCreateNavigate: newRegistryID => {
    ownProps.history.push(`registry/${newRegistryID}`);
    dispatch(registryCreateNavigateNew());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistryCreate)
);
