const registryTypes = [
  {
    key: 'W',
    value: 'Wedding',
  },
  {
    key: 'V',
    value: 'Birthday',
  },
  {
    key: 'B',
    value: 'Baby Shower',
  },
  {
    key: 'K',
    value: 'Kitchen Tea',
  },
  {
    key: 'H',
    value: 'House Warming',
  },
  {
    key: 'O',
    value: 'Other',
  },
];

export default registryTypes;
