const shops = [
  {
    key: 'Money',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Cifr%C3%A3o_symbol.svg',
    url: 'https://www.takealot.com',
  },
  {
    key: 'Takealot',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Takealot_logo.svg/2000px-Takealot_logo.svg.png',
    url: 'https://www.takealot.com',
  },
];

export default shops;
