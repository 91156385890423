import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.css';

import { loadReCaptcha } from 'react-recaptcha-v3';

import About from './components/About';
import Contact from './components/Contact';
import CustomMenu from './components/CustomMenu';
import Home from './components/Home';
import { MyRegistries } from './components/MyRegistries';
import { Registry, RegistryCreate, RegistryEdit, RegistryAdmin } from './components/Registry';
import { RegistryItemCreate, RegistryItemEdit, RegistryItemPurchase } from './components/RegistryItem';
import { Cart } from './components/Shop';
import {
  Signup,
  Login,
  Logout,
  Account,
  ForgotPassword,
  UserConfirmation,
  ResetPassword,
} from './components/Authentication';

import { loginDone } from './actions/authentication';

import { isLoggedIn } from './reducers/authentication';
import OffsitePurchase from './components/Shop/OffsitePurchase';

const App = ({ isLoggedIn, populateFromToken }) => {
  loadReCaptcha('6LdoUq8UAAAAAM3d5lLFlo3A_U9gAMBKxhkBNGKl');
  if (!isLoggedIn && localStorage.getItem('userToken')) {
    console.log('I am loading.... from local storage');
    var token = JSON.parse(localStorage.getItem('userToken'));
    populateFromToken(token);
    return <div>Logging you in again...</div>;
  } else
    return (
      <Router>
        <div id="outer-container" style={{ height: '100%' }}>
          <CustomMenu />
          <main id="page-wrap">
            <div className="App">
              <header className="App-header">
                {/* https://www.w3schools.com/howto/howto_css_hero_image.asp */}
                <nav className="navbar navbar-expand-md navbar-light bg-none">
                  <div className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto mt-2 mt-md-0" />
                    {isLoggedIn ? (
                      <div>
                        <Link className="menu-item--small" to="/logout">
                          <i className="fas fa-sign-out-alt" />
                          <span>Logout </span>
                        </Link>{' '}
                        {/* TODO: Cart */}
                        {/* <Link className="menu-item--small" to="/cart">
                          <i className="fas fa-shopping-cart" />
                          <span>Cart </span>
                        </Link>{" "} */}
                      </div>
                    ) : (
                      <div>
                        <div>
                          <Link className="menu-item--small" to="/signUp">
                            <i className="fas fa-user-plus" />
                            <span>Signup</span>
                          </Link>
                          {'  '}
                          <Link className="menu-item--small" to="/login">
                            <i className="fas fa-sign-in-alt" />
                            <span>Login</span>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </nav>

                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                <h1 className="App-title">
                  <br />
                  Welcome to Online Gift Registry
                </h1>
              </header>
              <div>
                <Route exact path="/" render={() => <Home />} />
                <Route path="/create" render={() => <RegistryCreate />} />
                <Route path="/cart" render={() => <Cart />} />

                <Switch>
                  <Route
                    path="/registry/:registryPath/item/:registryItemID/offsitePurchase"
                    render={() => <OffsitePurchase />}
                  />
                  <Route path="/registry/:registryID/item/:registryItemID/edit" render={() => <RegistryItemEdit />} />
                  <Route path="/registry/:registryID/item/:registryItemID" render={() => <RegistryItemPurchase />} />
                  <Route path="/registry/:registryID/addItem" render={() => <RegistryItemCreate />} />
                  <Route path="/registry/:registryID/admin" render={() => <RegistryAdmin />} />
                  <Route path="/registry/:registryID/edit" render={() => <RegistryEdit />} />
                  <Route path="/registry/:registryID" render={() => <Registry />} />
                </Switch>

                <Route
                  path="/registries"
                  render={() => <MyRegistries />}
                  onEnter={a => console.log("onEnter '/registries'", a)}
                />
                <Route path="/edit" render={() => <RegistryEdit />} />
                <Route path="/about" render={() => <About />} />
                <Route path="/contact" render={() => <Contact />} />

                {/* Account Routes: */}
                <Route path="/login" render={() => <Login />} />
                <Route path="/logout" render={() => <Logout />} />
                <Route path="/signUp" render={() => <Signup />} />
                <Route path="/account" render={() => <Account />} />
                <Route path="/UserConfirmation" render={() => <UserConfirmation />} />
                <Route path="/forgotPassword" render={() => <ForgotPassword />} />
                <Route path="/resetPassword" render={() => <ResetPassword />} />

                {/*
          TODO: Possibly use   <Route component={EnsureLoggedInContainer}> .... />
           https://medium.com/the-many/adding-login-and-authentication-sections-to-your-react-or-react-native-app-7767fd251bd1 
          */}
              </div>
            </div>
            <br />
            <br />
          </main>
        </div>
      </Router>
    );
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogin: () => {},
  onSignup: () => {},
  onLogout: () => {},

  populateFromToken: token => {
    dispatch(loginDone(token));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
