import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { registrySearchEdit, registrySearchSelect, registrySearch } from '../actions/registrySearch';
import {
  registrySearchText,
  registrySearchResults,
  registrySearchBusy,
  registrySearchError,
} from '../reducers/registrySearch';
import Error from './Error';

const Home = ({
  registrySearchText,
  onEditRegistrySearch,
  registrySearchResults,
  onSelectRegistry,
  onRegistrySearch,
  registrySearchBusy,
  registrySearchError,
}) => {
  return (
    <div>
      <h1>Home Page:</h1>
      <p>A Place to collect all your favourite goodies! Happy shopping!</p>
      <div className="container-fluid" style={{ maxWidth: '250px' }}>
        <div className="form-group">
          <br />
          {/* <label>Search for a registry:</label> */}
          <input
            placeholder="Search for a registry..."
            className="form-control"
            value={registrySearchText || ''}
            onChange={({ target }) => onEditRegistrySearch(target.value)}
            onKeyDown={({ keyCode }) => (keyCode === 13 ? onRegistrySearch(registrySearchText) : {})}
          />
          <button className="btn btn-primary" onClick={() => onRegistrySearch(registrySearchText)}>
            Find!
          </button>
        </div>
      </div>

      {registrySearchError ? (
        <Error error={registrySearchError.message} />
      ) : (
        <div>
          {registrySearchBusy ? 'SEARCHING...' : ''}
          <br />
          {registrySearchResults.length <= 0 ? 'No Results, try searching for something' : ''}
          <div className="container">
            {registrySearchResults.map((item, index) => (
              <div className="row" key={index} onClick={() => onSelectRegistry(item)}>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">{item.registryTitle}</div>
                    <div className="card-body">{item.registryDescription}</div>
                  </div>
                  <br />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

Home.defaultProps = {
  registrySearchResults: [],
  registrySearchText: '',
  registrySearchError: undefined,
};

const mapStateToProps = state => ({
  registrySearchText: registrySearchText(state),
  registrySearchResults: registrySearchResults(state),
  registrySearchError: registrySearchError(state),
  registrySearchBusy: registrySearchBusy(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditRegistrySearch: searchString => {
    dispatch(registrySearchEdit(searchString));
  },
  onRegistrySearch: searchString => {
    dispatch(registrySearch(searchString));
  },
  onSelectRegistry: selectedRegistry => {
    dispatch(registrySearchSelect(selectedRegistry));
    ownProps.history.push(`/registry/${selectedRegistry.registryPath}`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
