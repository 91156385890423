import React from 'react';

const RegistryAdmin = () => {
  return (
    <div>
      <h1>RegistryAdmin:</h1>
    </div>
  );
};

export default RegistryAdmin;
