import {
  NEW_REGISTRY_ITEM_EDIT_FIELD,
  NEW_REGISTRY_ITEM_INSERT_REQUEST,
  NEW_REGISTRY_ITEM_INSERT_SUCCESS,
  NEW_REGISTRY_ITEM_INSERT_FAILURE,
  REGISTRY_ITEMS_FETCH_REQUEST,
  REGISTRY_ITEMS_FETCH_SUCCESS,
  REGISTRY_ITEMS_FETCH_FAILURE,
  REGISTRY_ITEM_DELETE_REQUEST,
  REGISTRY_ITEM_DELETE_SUCCESS,
  REGISTRY_ITEM_DELETE_FAILURE,
  REGISTRY_ITEM_EDITING,
  REGISTRY_ITEM_EDIT_SAVE_REQUEST,
  REGISTRY_ITEM_EDIT_START,
  REGISTRY_ITEM_CANCEL,
  REGISTRY_ITEM_EDIT_SAVE_SUCCESS,
  REGISTRY_ITEM_EDIT_SAVE_FAILURE,
} from '../actions/registryItems';

// selectors
const name = 'registryStore';

export const newRegistryItem = ({ [name]: { registryItems } }) => registryItems.newItem;
export const registryItems = ({ [name]: { registryItems } }) => registryItems;
export const fetchingItemList = ({ [name]: { registryItems } }) => registryItems.fetchingItemList;
export const editingItem = ({ [name]: { registryItems } }) => registryItems.editingItem;

// TODO double == as we are comparing int to string
export const findRegistryItem = ({ [name]: { registryItems } }, registryItemID) =>
  registryItems.registryItems ? registryItems.registryItems.find(r => r.registryItemID === registryItemID) : undefined;

// ERROR Selectors
export const fetchRegistryItemListError = ({ [name]: { registryItems } }) => registryItems.fetchRegistryItemListError;

// reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                   NEW EDIT:                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case NEW_REGISTRY_ITEM_EDIT_FIELD:
      return {
        ...state,
        newItem: {
          ...state.newItem,
          [action.newItemFieldName]: action.newItemFieldValue,
          registry: action.registry,
        },
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                EXISTING EDIT:                                                  //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_ITEM_EDIT_START:
      return {
        ...state,
        editingItem: action.registryItem,
      };
    case REGISTRY_ITEM_EDITING:
      return {
        ...state,
        editingItem: {
          ...state.editingItem,
          [action.editItemFieldName]: action.editItemFieldValue,
        },
      };
    case REGISTRY_ITEM_EDIT_SAVE_REQUEST:
      return {
        ...state,
        registryItemEditSavingBusy: true,
        registryItemEditSavingError: undefined,
      };
    case REGISTRY_ITEM_EDIT_SAVE_SUCCESS:
      return {
        ...state,
        editingItem: {},
        registryItems: state.registryItems.map(i =>
          i.registryItemID === action.editingItem.registryItemID ? action.editingItem : i
        ),
        registryItemEditSavingBusy: undefined,
        registryItemEditSavingError: undefined,
      };

    case REGISTRY_ITEM_EDIT_SAVE_FAILURE:
      return {
        ...state,
        editingItem: {}, //CLEAR changes if error saving...
        registryItemEditSavingBusy: undefined,
        registryItemEditSavingError: action.error,
      };
    case REGISTRY_ITEM_CANCEL:
      return {
        ...state,
        editingItem: {},
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                     DELETE:                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_ITEM_DELETE_REQUEST:
      return {
        ...state,
        registryItemDeleteBusy: true,
        registryItemDeleteError: undefined,
      };
    case REGISTRY_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        registryItems: state.registryItems.filter(
          registryItems => registryItems.registryItemID !== action.registryItemID
        ),
        registryItemDeleteBusy: undefined,
        registryItemDeleteError: undefined,
      };
    case REGISTRY_ITEM_DELETE_FAILURE:
      return {
        ...state,
        registryItemDeleteBusy: undefined,
        registryItemDeleteError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                    INSERT:                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    case NEW_REGISTRY_ITEM_INSERT_REQUEST:
      return {
        ...state,
        savingItem_busy: true,
        savingItem_FAILURE: undefined,
      };
    case NEW_REGISTRY_ITEM_INSERT_SUCCESS:
      return {
        ...state,
        newItem: {},
        savingItem_busy: undefined,
        savingItem_FAILURE: undefined,
        registryItems: [...state.registryItems].concat(action.newRegistryItem),
      };
    case NEW_REGISTRY_ITEM_INSERT_FAILURE:
      return {
        ...state,
        savingItem_busy: undefined,
        savingItem_FAILURE: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                     FETCH:                                                     //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case REGISTRY_ITEMS_FETCH_REQUEST:
      return {
        ...state,
        fetchingItemList: true,
        fetchRegistryItemListError: undefined,
      };
    case REGISTRY_ITEMS_FETCH_SUCCESS:
      return {
        ...state,
        fetchingItemList: undefined,
        fetchRegistryItemListError: undefined,
        registryID: action.registryID,
        registryItems: action.list,
      };
    case REGISTRY_ITEMS_FETCH_FAILURE:
      return {
        ...state,
        fetchingItemList: undefined,
        fetchRegistryItemListError: action.error,
      };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //                                                    DEFAULT:                                                    //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
};

export default reducer;
