// constants
export const CREDENTIALS_EDITING = 'CREDENTIALS_EDITING';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CONFIRM = 'LOGIN_CONFIRM';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const SIGNUP_EDITING = 'SIGNUP_EDITING';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_EDITING = 'FORGOT_PASSWORD_EDITING';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_EDITING = 'RESET_PASSWORD_EDITING';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE';

export const USER_CONFIRMATION_REQUEST = 'USER_CONFIRMATION_REQUEST';
export const USER_CONFIRMATION_SUCCESS = 'USER_CONFIRMATION_SUCCESS';
export const USER_CONFIRMATION_FAILURE = 'USER_CONFIRMATION_FAILURE';

export const RESEND_CONFIRMATION_REQUEST = 'RESEND_CONFIRMATION_REQUEST';
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS';
export const RESEND_CONFIRMATION_FAILURE = 'RESEND_CONFIRMATION_FAILURE';

////////////////////////////////////////////////////////////////////////////////
//                                   LOGIN                                    //
////////////////////////////////////////////////////////////////////////////////
export const editLoginFields = (field, value) => {
  return {
    type: CREDENTIALS_EDITING,
    field,
    value,
  };
};
export const loginRequest = credentials => {
  return {
    type: LOGIN_REQUEST,
    credentials,
  };
};
export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};
export const loginDone = token => {
  return {
    type: LOGIN_SUCCESS,
    token,
  };
};
export const loginError = error => {
  return {
    type: LOGIN_FAILURE,
    error,
  };
};
export const userNotConfirmed = error => {
  return {
    type: LOGIN_CONFIRM,
    error,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                                   SIGNUP                                   //
////////////////////////////////////////////////////////////////////////////////
export const signUpEditing = (field, value) => {
  return {
    type: SIGNUP_EDITING,
    field,
    value,
  };
};

export const signUpRequest = newUser => {
  return {
    type: SIGNUP_REQUEST,
    newUser,
  };
};

export const signUpSuccess = () => {
  return {
    type: SIGNUP_SUCCESS,
  };
};

export const signUpFailure = error => {
  return {
    type: SIGNUP_FAILURE,
    error,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                              USER CONFIRMATION                             //
////////////////////////////////////////////////////////////////////////////////
export const confirmUser = (userID, token) => {
  return {
    type: USER_CONFIRMATION_REQUEST,
    userID,
    token,
  };
};

export const confirmUserDone = () => {
  return {
    type: USER_CONFIRMATION_SUCCESS,
  };
};

export const confirmUserError = error => {
  return {
    type: USER_CONFIRMATION_FAILURE,
    error,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                             RESEND CONFIRMATION                            //
////////////////////////////////////////////////////////////////////////////////
export const resendConfirmation = email => {
  return {
    type: RESEND_CONFIRMATION_REQUEST,
    email,
  };
};
export const resendConfirmationDone = () => {
  return {
    type: RESEND_CONFIRMATION_SUCCESS,
  };
};
export const resendConfirmationFail = error => {
  return {
    type: RESEND_CONFIRMATION_FAILURE,
    error,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                               FORGOT PASSWORD                              //
////////////////////////////////////////////////////////////////////////////////
export const forgotPasswordEdit = (field, value) => {
  return {
    type: FORGOT_PASSWORD_EDITING,
    field,
    value,
  };
};

export const forgotPassword = forgotPasswordFields => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    forgotPasswordFields,
  };
};

export const forgotPasswordDone = () => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  };
};

export const forgotPasswordError = error => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    error,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                               RESET PASSWORD                               //
////////////////////////////////////////////////////////////////////////////////
export const resetPasswordEdit = (field, value) => {
  return {
    type: RESET_PASSWORD_EDITING,
    field,
    value,
  };
};

export const resetPassword = (newPassword, userID, token) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    newPassword,
    userID,
    token,
  };
};

export const resetPasswordDone = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordError = error => {
  return {
    type: RESET_PASSWORD_FAILURE,
    error,
  };
};

////////////////////////////////////////////////////////////////////////////////
//                                 DELETE USER                                //
////////////////////////////////////////////////////////////////////////////////
export const deleteUser = () => {
  return {
    type: USER_DELETE_REQUEST,
  };
};

export const deleteUserDone = () => {
  return {
    type: USER_DELETE_SUCCESS,
  };
};

export const deleteUserError = error => {
  return {
    type: USER_DELETE_FAILURE,
    error,
  };
};
