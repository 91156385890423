// constants
export const NEW_OFFSITE_TRANSACTION_EDITING = 'NEW_OFFSITE_TRANSACTION_EDITING';

export const NEW_OFFSITE_TRANSACTION_REQUEST = 'NEW_OFFSITE_TRANSACTION_REQUEST';
export const NEW_OFFSITE_TRANSACTION_SUCCESS = 'NEW_OFFSITE_TRANSACTION_SUCCESS';
export const NEW_OFFSITE_TRANSACTION_FAILURE = 'NEW_OFFSITE_TRANSACTION_FAILURE';

// action creators
export const offsiteTransactionQuantityEdit = quantity => {
  return {
    type: NEW_OFFSITE_TRANSACTION_EDITING,
    quantity,
  };
};

export const newOffsiteTransaction = (registryPath, registryItemID, quantity) => {
  return {
    type: NEW_OFFSITE_TRANSACTION_REQUEST,
    registryPath,
    registryItemID,
    quantity,
  };
};
export const newOffsiteTransactionDone = () => {
  return {
    type: NEW_OFFSITE_TRANSACTION_SUCCESS,
  };
};
export const newOffsiteTransactionError = error => {
  return {
    type: NEW_OFFSITE_TRANSACTION_FAILURE,
    error,
  };
};
