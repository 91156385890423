import React from 'react';
import Error from '../Error';

const Cart = () => {
  return (
    <div>
      <h1>Cart:</h1>
      <p>
        <i>These are purchases for credit card:</i>
      </p>
      <Error error={'Not Implemented'} />
    </div>
  );
};

export default Cart;
