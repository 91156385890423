import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Member from './Member';
import SearchSelection from './SearchSelection';
import Admin from './Admin';
import Owned from './Owned';
import { isLoggedIn } from '../../reducers/authentication';
import NotLoggedIn from '../NotLoggedIn';

const MyRegistries = ({ location, isLoggedIn }) => {
  if (!isLoggedIn) return <NotLoggedIn returnUrl={encodeURI(location.pathname)} />;
  var component = {};
  switch (location.hash) {
    case '#owner':
      component = <Owned />;
      break;
    case '#admin':
      component = <Admin />;
      break;
    case '#member':
      component = <Member />;
      break;
    default:
      component = [];
  }

  return (
    <div>
      <h1>My Registries:</h1>
      <SearchSelection />
      <br />
      {component}
    </div>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyRegistries)
);
