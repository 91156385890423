import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isLoggedIn } from '../../reducers/authentication';
import { deleteUser, logoutRequest } from '../../actions/authentication';
import NotLoggedIn from '../NotLoggedIn';

// const columns = [
//   {
//     Header: "Title",
//     accessor: "registryTitle"
//   },
//   {
//     Header: "Created Date",
//     accessor: "createdDate",
//     show: false // Sorting Column
//   }
// ];

const Account = ({ location, isLoggedIn, deleteAccount }) => {
  return (
    <div>
      <p>Is logged in: {isLoggedIn ? `yes` : `no`}</p>
      {!isLoggedIn ? (
        <NotLoggedIn returnUrl={encodeURI(location.pathname)} />
      ) : (
        <div>
          <h1>My Account:</h1>
          <h2>Danger Section:</h2>
          Delete my account:
          <br />
          <button className="btn btn-danger" onClick={() => deleteAccount()}>
            Delete Account
          </button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          &lt;&lt;&lt; Name &gt;&gt;&gt;
          <br />
          &lt;&lt;&lt; LastName &gt;&gt;&gt;
          <br />
          &lt;&lt;&lt; Email &gt;&gt;&gt;
          <br />
          &lt;&lt;&lt; Contact &gt;&gt;&gt;
          <br />
          &lt;&lt;&lt; Purchase history&gt;&gt;&gt;
          {/* <ReactTable
            className="-striped -highlight"
            data={}
            columns={columns}
            sorted={[{ id: "createdDate" }]}
            getTrProps={handleRowClick()}
          /> */}
        </div>
      )}
    </div>
  );
};

// const handleRowClick = a => (state, rowInfo) => {
//   console.log("a: ", a);
//   return {
//     onClick: () => {
//       // TODO: first check if we have an ID...
//       //      push(`registry/${rowInfo.original.registryID}`);
//     }
//   };
// };

const mapStateToProps = (state, ownProps) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteAccount: () => {
    dispatch(deleteUser());
    localStorage.removeItem('userToken');
    dispatch(logoutRequest());
    ownProps.history.push('/');
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Account)
);
