import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isLoggedIn } from '../../reducers/authentication';
import NotLoggedIn from '../NotLoggedIn';
import { findRegistryItem, registryItems, fetchingItemList } from '../../reducers/registryItems';
import Error from '../Error';
import { fetchRegistryItemsList } from '../../actions/registryItems';
import RegistryCard from './RegistryCard';
import { currencies } from '../../reducers/referenceData';
import { extractRootDomain } from '../../helpers/urlHelper';

const RegistryItemPurchase = ({
  location,
  match,
  isLoggedIn,
  onClick,
  registryItem,
  registryItems,
  isFetchingList,
  onFetchRegistryItems,
  currencies,
}) => {
  // TODO: do not hard code currency here...
  const selectedCurrency = currencies.find(c => c.currencyCode === 'ZAR');

  if (!registryItems.registryItems) {
    if (!isFetchingList) {
      onFetchRegistryItems(match.params.registryID);
    }
    return <div>The item was not found, we're quickly checking the database again... whoops</div>;
  }

  console.log('purchaseType:', registryItem.purchaseType);

  if (!isLoggedIn) return <NotLoggedIn returnUrl={encodeURI(location.pathname)} />;
  if (!registryItem) return <Error error={'Item not found in store'} />;
  console.log(registryItem.url);
  return (
    <div>
      <h1>Purchase Item(s):</h1>
      <br />
      <br />

      <div className="row">
        <div className="col-md-2" />
        <div className="col-md-4">
          <RegistryCard item={registryItem} selectedCurrency={selectedCurrency} cardAction={undefined} />
        </div>
        <div className="col-md-4">
          <div className="row">
            {registryItem.purchaseType > 1 ? (
              <div>
                <div className="card" onClick={() => onClick('offsitePurchase')}>
                  <div
                    className="card-img-top fas fa-truck fa-9x"
                    style={{ background: 'GHOSTWHITE', color: 'SteelBlue' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Buy item</h5>
                    <p className="card-text">
                      I will buy this gift from www.{extractRootDomain(registryItem.url)} and get it to the registrant.
                    </p>
                  </div>
                </div>
                <br />
                <br />
              </div>
            ) : (
              <div />
            )}

            {registryItem.purchaseType !== 2 ? (
              <div>
                <div className="card" onClick={() => onClick('online')}>
                  <div
                    className="card-img-top fas fa-credit-card fa-9x"
                    style={{ background: 'GHOSTWHITE', color: 'SteelBlue' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">Add to Cart</h5>
                    <p className="card-text">
                      <s>I will this to my card and send cash for the gift so they can have fun shopping!</s>
                      <br />
                      This is not working yet - please consider an EFT.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className="col-md-2" />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  // TODO this broken
  //currency: currency(state, ownProps.registryID),
  currencies: currencies(state),
  isLoggedIn: isLoggedIn(state),
  registryItems: registryItems(state),
  isFetchingList: fetchingItemList(state),
  registryItem: findRegistryItem(state, ownProps.match.params.registryItemID),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: path => {
    ownProps.history.push(`${ownProps.match.url}/${path}`);
  },
  onFetchRegistryItems: registryPath => {
    dispatch(fetchRegistryItemsList(registryPath));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegistryItemPurchase)
);
