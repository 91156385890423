import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { findRegistryItem, registryItems, fetchingItemList } from '../../reducers/registryItems';
import { fetchRegistryItemsList } from '../../actions/registryItems';
import {
  newOffsiteTransactionQuantity,
  newOffsiteTransactionBusy,
  newOffsiteTransactionDone,
  newOffsiteTransactionError,
} from '../../reducers/offsiteTransaction';
import { offsiteTransactionQuantityEdit, newOffsiteTransaction } from '../../actions/offsiteTransaction';
import { isLoggedIn } from '../../reducers/authentication';
import NotLoggedIn from '../NotLoggedIn';

const OffsitePurchase = ({
  match,
  onBack,
  location,
  registryItem,
  registryItems,
  isFetchingList,
  onFetchRegistryItems,
  newOffsiteTransactionQuantity,
  newOffsiteTransactionBusy,
  newOffsiteTransactionDone,
  newOffsiteTransactionError,
  onEditQuantity,
  onGoShopping,
  goToRegistry,
  onPostOffsiteTransaction,
  isLoggedIn,
}) => {
  if (!isLoggedIn) return <NotLoggedIn returnUrl={encodeURI(location.pathname)} />;

  if (!registryItems.registryItems) {
    if (!isFetchingList) {
      onFetchRegistryItems(match.params.registryPath);
    }
    return <div>The item was not found, we're quickly checking the database again... whoops</div>;
  }

  switch (location.hash) {
    case '#done':
      return (
        <div>
          {`How many did you buy?`}
          <br />
          <br />
          <div className="w-75 mx-auto" style={{ maxWidth: '300px' }}>
            <div className=" m-3">
              <div className="form-group">
                <label>I bought:</label>
                <div style={{ placeContent: 'center', alignContent: 'center' }}>
                  <input
                    min="1"
                    type="number"
                    className="form-control"
                    placeholder="Enter the number you bought..."
                    value={newOffsiteTransactionQuantity || {}}
                    onChange={({ target }) => onEditQuantity(target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-danger" onClick={() => goToRegistry(match.params.registryPath)}>
            None
          </button>{' '}
          <button
            className="btn btn-primary"
            onClick={() =>
              onPostOffsiteTransaction(
                match.params.registryPath,
                match.params.registryItemID,
                newOffsiteTransactionQuantity
              )
            }
          >
            That many
          </button>
          <p>{newOffsiteTransactionBusy ? 'resendConfirmationBusy' : ''}</p>
          <p>{newOffsiteTransactionError ? newOffsiteTransactionError.message : ''}</p>
        </div>
      );
    default:
      return (
        <div>
          <h1>Offline Purchase:</h1>
          <h2>{`You are buying '${registryItem.description}':`}</h2>
          <br />
          {`They want ${registryItem.quantityRequested} and ${registryItem.quantityPurchased} have been bought.`}
          <br />
          {`That means they need ${registryItem.quantityRequested - registryItem.quantityPurchased}`}
          <br />
          <br />
          <button className="btn btn-secondary" onClick={() => onBack()}>
            Cancel
          </button>{' '}
          <button className="btn btn-primary" onClick={() => onGoShopping(registryItem.url)}>
            Take me Shopping!
          </button>
        </div>
      );
  }
};

const mapStateToProps = (state, ownProps) => ({
  newOffsiteTransactionQuantity: newOffsiteTransactionQuantity(state),
  newOffsiteTransactionBusy: newOffsiteTransactionBusy(state),
  newOffsiteTransactionDone: newOffsiteTransactionDone(state),
  newOffsiteTransactionError: newOffsiteTransactionError(state),
  registryItem: findRegistryItem(state, ownProps.match.params.registryItemID),
  registryItems: registryItems(state),
  isFetchingList: fetchingItemList(state),
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onEditQuantity: qty => {
    dispatch(offsiteTransactionQuantityEdit(qty));
  },
  onFetchRegistryItems: registryPath => {
    dispatch(fetchRegistryItemsList(registryPath));
  },
  onBack: () => {
    ownProps.history.goBack();
  },
  goToRegistry: registryPath => {
    ownProps.history.push(`/registry/${registryPath}`);
  },
  onGoShopping: url => {
    window.open(`${url}`, '_blank');
    ownProps.history.push(`${ownProps.match.url}#done`);
  },
  onPostOffsiteTransaction: (registryPath, registryItemID, qty) => {
    console.log('registryItemID', registryItemID);
    dispatch(newOffsiteTransaction(registryPath, registryItemID, qty));
    dispatch(fetchRegistryItemsList(registryPath));
    ownProps.history.push(`/registry/${registryPath}`);
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OffsitePurchase)
);
